<template>
    
    <div class="" @mouseenter="show()" @mouseleave="hide()">
        <div :class="[
            showStatus == true ? 'nar-tooltip absolute visible z-100' : 'fixed invisible z-100', positionClass
        ]" :id="`${id}-nartooltip`" :style="{
            left: pos_left +'px',
            top: pos_top +'px',
            maxHeight: '500px',
            zIndex: '1000',
            width: width == 0 ? 'auto' : width+'px',

        }">
            
            <div class="relative z-100 flex flex-row items-center justify-center">

                <div class="flex flex-row w-full items-center justify-start bg-white px-2 py-1 br-10">
                    <div class="h-full w-full relative">
                        <h3 v-if="title" class="f14-ls09 text-green font-bold text-left">{{ title }}</h3>
                        <div v-if="description" :class="[
                            'f12-ls011 w-full h-full',
                            'text-'+textAlign
                            ] ">
                            {{ description }}
                        </div>
                    </div>
                </div>
                
                
            </div>


        </div>
        <slot></slot>
    </div>
</template>

<style>
    @import './tooltip.css';
</style>

<script>
// import Close from "vue-material-design-icons/Close.vue";

export default{
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        description: {
            type: String,
            required: true,
            default: null
        },
        position: {
            type: String,
            required: false,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        width: {
            type: Number, 
            required: false,
            default: 0
        },
        textAlign: {
            type: String, 
            required: false,
            default: 'left'
        }
    },
    created() {
        window.addEventListener('scroll', this.hide);
        
    },
    destroyed() {
        window.removeEventListener('scroll', this.hide);
    },
    methods: {
        show(){

            this.showStatus = true;

            let target = document.getElementById(this.id);
            let tooltip = document.getElementById( this.id + '-nartooltip');
            
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;

            let tooltipWidth = tooltip.getBoundingClientRect().width;
            let tooltipHeight = tooltip.getBoundingClientRect().height;
            let tooltipLeft = tooltip.getBoundingClientRect().left;
            let tooltipTop = tooltip.getBoundingClientRect().top;

            let targetWidth = target.getBoundingClientRect().width;
            let targetHeight = target.getBoundingClientRect().height;
            let targetLeft = target.getBoundingClientRect().left;
            let targetTop = target.getBoundingClientRect().top;

            if( windowWidth < tooltipWidth ) {
                tooltip.style.maxWidth = windowWidth / 2;
            } else {
                tooltip.style.maxWidth = 340;
            }

            let pos_left = targetLeft + tooltipWidth;
            let pos_top  = target.offsetTop - tooltipHeight - 20;
            
            
            if( pos_left > windowWidth ) {
                this.pos_left = target.offsetLeft - tooltip.offsetWidth + target.offsetWidth + 5;
                this.pos_top = target.offsetTop - tooltip.offsetHeight - 10;
                this.positionClass = 'pos-right';
            } else if( ((targetLeft - tooltipWidth) / 2) - 20 < 0 ) {
                this.pos_left = target.offsetLeft + targetWidth / 2 - 20;
                this.pos_top = target.offsetTop - tooltip.offsetHeight - 10;
                this.positionClass = 'pos-left';
            } else {
                this.pos_left = target.offsetLeft + (target.offsetWidth/2) - (tooltip.offsetWidth/2);

                this.pos_top = target.offsetTop - tooltip.offsetHeight - 10;
                this.positionClass = 'pos-bottom';
            }
            
            
        },
        hide(){
            this.showStatus = false;
            // this.positionClass = '';
        }
        
    },
    data() {
        return {
            showStatus: false,
            positionClass: '',
            pos_top: 0,
            pos_left: 0
        }
    },
    components: {
        // Close
    }
}
</script>