<template>
    <!-- <section class="lg:w-10/12 w-full mx-auto bg-green p-6 br-10"  v-if="!additionalInfo.wantsAgencyValuation && requestType=='vendi'"> -->
    <section class="lg:w-10/12 w-full mx-auto bg-green house-ok-section">
        <p class=" house-ok-sub-heading">PRONTO A VENDERE?</p>
        <h4 class="text-2xl text-white font-bold house-ok-heading">Ecco le proposte Newarc per te!</h4>

        <div class="flex lg:flex-row flex-col lg:p-0 p-2 justify-center items-center">

            <div class="flex flex-col items-center justify-center house-ok-left house-ok">

                <img class="mx-auto house-ok-icon" src="@/assets/img/icons/subito-icon.png" alt="" />
                <img class="mx-auto house-ok-image" src="@/assets/img/newarc-subito.png" alt="" />

                <p class="house-ok-text">Vendi a noi la tua casa e ottieni subito tutta la liquidità.</p>

                <!-- <button v-if="subitoSent == false" class="house-ok-button" type="button" style="min-width: 300px" v-on:click="confirmWantSellSubito()">Voglio vendere subito</button> -->
                <a class="house-ok-button" href="https://www.newarc.it/newarc-subito/">Voglio vendere subito</a>

            </div>
            <div class="flex flex-col items-center justify-center house-ok-right house-ok">

                <img class="mx-auto house-ok-icon" src="@/assets/img/icons/insieme-icon.png" alt="" />
                <img class="mx-auto house-ok-image" src="@/assets/img/newarc-insieme.png" alt="" />

                <p class="house-ok-text">Noi ristrutturiamo e vendiamo la casa. Tu guadagni di più.</p>
                
                <!-- <button v-if="insiemeSent == false" class="house-ok-button" type="button" v-on:click="confirmWantKnowMoreInsieme()">Voglio saperne di più</button> -->
                <a class="house-ok-button" href="https://www.newarc.it/newarc-insieme/">Voglio saperne di più</a>

                </div>

        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
  props: {
    additionalInfo: Object,
    requestType: String,
    sentRequest: Boolean,
    firestoreId: String
  },
  data() {
    return {
      insiemeSent: false,
      subitoSent: false
    }
    
  },
  methods: {
    /*setRequestSent(status) {
      console.log(status);
      this.sentRequest = status;
      this.$emit('setRequestSent', status);
    },*/
    confirmWantKnowMoreInsieme() {
      console.log("clicked insieme")
      // this.setRequestSent(true);
      // return;
      axios
        .post(
        process.env.VUE_APP_updateInsiemeAgreement,
          {
            firestoreId: this.firestoreId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.insiemeSent = true;
          //this.setRequestSent(true);
        });
    },
    confirmWantSellSubito() {
      console.log("clicked subito")
    
      axios
        .post(
          process.env.VUE_APP_updateSubitoInterest,
          {
            firestoreId: this.firestoreId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.subitoSent = true;

          // this.sentRequest = true;
          //this.setRequestSent(true);
        });
    },
  },
}
</script>