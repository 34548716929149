<template>
  <div v-on:click="hideGoogleAddress()" id="top">



    <div class="relative flex relative bg-white rounded-full justify-left mt-4 " style="border: 1px solid #D0D0D0;">
      <input v-model="houseAddress" type="address" placeholder="Inserisci l'indirizzo per iniziare..." ref="houseAddress"
        v-on:keyup="delayBeforeSearch()" v-on:keyup.enter="goToConfigurator()"
        class="no-border-input lg:pl-14 pl-5 pr-4 lg:py-6 py-4 w-full rounded-full pac-target-input banner-search-elements"
         />

      <button
        class="rounded-full px-10 lg:py-6 p-3 bg-green text-white flex relative absolute right-0 items-center banner-search-elements na-confirm-address-button font-bold "
        @click="goToConfigurator()">
        <span :class="[
          'font-bold f20-ls04 mr-4 na-search-button-label',
          isMobileDevice() ? 'is-mobile' : ''
        ]">Iniziamo</span>
        <img class="mx-auto" src="@/assets/img/icons/arrow-right.svg" style="width: 20px;" alt="" />
      </button>

      <ul class="absolute na-google-prediction" v-if="placesPrediction.length > 0">
        <li v-on:click="setGoogleAddress(i)" v-for="(place, i) in placesPrediction" :key="i">
          {{ place.description }}
        </li>
      </ul>
    </div>


    <div id="hidden-tmp-map" class="hidden"></div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapExample from "@/components/Maps/MapExample.vue";
import { createPopper } from "@popperjs/core";
import { Anitmate } from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


var presetAddress = null;

export default {
  created() {
    if (typeof this.$route.query.tipo != 'undefined') {
      console.log(this.$route.query.tipo);
      this.requestType = this.$route.query.tipo;
    }
  },
  mounted() {

    window.addEventListener('scroll', this.handleSCroll);
    window.addEventListener('resize', this.handleWindowResize);

    this.screen_width = window.innerWidth;
    console.log(this.screen_width);
    console.log(window.location.pathname)

  },
  methods: {
    isMobileDevice() {
      return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    handleWindowResize() {
      this.screen_width = window.innerWidth;
      console.log(this.screen_width);
    },
    handleSCroll() {

      var list = document.querySelectorAll('.animate__animated');

      for (var i = 0; i < list.length; i++) {
        var me = list[i];
        if ((me.offsetTop - (window.innerHeight / 1.3)) <= window.scrollY && !me.classList.contains('animate__fadeInUp')) {
          me.classList.add("animate__fadeInUp");
        }
      }

      let header = document.querySelector(".newarc-menu");
      if (window.scrollY > 50) {
        header.classList.add('menu-sticky');
        // header.classList.remove('fixed'); 
      } else {
        header.classList.remove('menu-sticky');
        // header.classList.add('fixed'); 
      }


    },
    toggleSlidesTime() {
      if (this.slidesTimeTimerId) {
        clearInterval(this.slidesTimeTimerId)
        this.slidesTimeTimerId = 0
      } else {
        this.updateSlidesWithTime()
        this.slidesTimeTimerId = setInterval(this.updateSlidesWithTime, 1000)
      }
    },
    updateSlidesWithTime() {
      this.slides.forEach(slide => {
        let time = new Date()
        slide.title = time.toLocaleTimeString()
        slide.content = 'Time in 5 hours: ' + new Date(time.getTime() + 5 * 3600000).toLocaleTimeString()
      })
    },
    toggleTooltip(i) {
      let ref;
      let tooltipRef;
      if (i == 0) {
        ref = this.$refs.btnRef0;
        tooltipRef = this.$refs.tooltipRef0;
      } else if (i == 1) {
        ref = this.$refs.btnRef1;
        tooltipRef = this.$refs.tooltipRef1;
      } else if (i == 2) {
        ref = this.$refs.btnRef2;
        tooltipRef = this.$refs.tooltipRef2;
      }

      if (this.tooltipShow[i]) {
        this.tooltipShow[i] = false;
      } else {
        this.tooltipShow[i] = true;
        createPopper(ref, tooltipRef, {
          placement: "bottom",
        });
      }
    },
    goToTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 130)
      }, 20)
    },
    setLocation(latitude, longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
    },
    async delayBeforeSearch() {
      this.placesPrediction = [{ description: "Searching..." }];
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.checkPrediction();
      }, this.delayBeforeSearchTimer);

    },
    async checkPrediction() {

      let ignore_words = [
        "corso via",
        "via corso"
      ];

      const found_ignored = ignore_words.findIndex(element => {
        return element.toLowerCase() === this.houseAddress.toLowerCase().trim();
      });

      if (this.houseAddress.length < 4 || found_ignored === 0) return;

      const service = new google.maps.places.AutocompleteService();

      // Reset the presetAddress if any
      presetAddress = null;
      // Reset flag
      this.placeSelected = false;

      service.getPlacePredictions(
        {
          input: this.houseAddress,
          componentRestrictions: { country: "it" },
          fields: ['formatted_address', 'geometry', 'name'],
        },
        function (predictions, status) {

          if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.log(status);
            presetAddress = null;
            return;
          }

        }).then((predictions) => {

          if (predictions['predictions'].length === 0) return;

          this.placesPrediction = [];
          const map = new google.maps.Map(
            document.getElementById("hidden-tmp-map"),
            {
              center: { lat: -33.866, lng: 151.196 },
              zoom: 15,
            }
          );

          for (let index = 0; index < predictions['predictions'].length; index++) {
            const placeElement = predictions['predictions'][index];
            this.placesPrediction.push(placeElement);
          }

          const request = {
            placeId: predictions['predictions'][0]['place_id']
          };

          const service = new google.maps.places.PlacesService(map);

          service.getDetails(request, (place, status) => {

            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              place &&
              place.geometry &&
              place.geometry.location
            ) {

              // Store first address in the list if there is enter press.
              this.firstAddress = place;

            }
          });

          this.placeSelected = false;
          presetAddress = null;

          // this.placesPrediction = placePredict;
          return;
        });
    },
    hideGoogleAddress() {
      this.placesPrediction = [];
    },
    async setGoogleAddress(index) {

      // Set the address if there is a selection from the list.
      const map = new google.maps.Map(
        document.getElementById("hidden-tmp-map"),
        {
          center: { lat: -33.866, lng: 151.196 },
          zoom: 15,
        }
      );

      const request = {
        placeId: this.placesPrediction[index]['place_id']
      };
      const service = new google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {

        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place &&
          place.geometry &&
          place.geometry.location
        ) {

          this.placeSelected = true;
          presetAddress = place;

        }
      });

      this.houseAddress = this.placesPrediction[index].description;

    },
    goToConfigurator() {
      let postalCode = "";
      let streetNumber = "";
      let address = "";
      let city = "";

      var divsToHide = document.getElementsByClassName("pac-container"); //divsToHide is an array
      for (var i = 0; i < divsToHide.length; i++) {
        divsToHide[i].style.display = "none"; // depending on what you're doing
      }

      if (this.placeSelected == false && presetAddress == null) {

        this.houseAddress = this.firstAddress.formatted_address;
        this.googleAddress = this.firstAddress;

      } else if (this.placeSelected == true && presetAddress != null) {

        this.placeSelected = true;
        this.houseAddress = presetAddress.formatted_address;
        this.googleAddress = presetAddress;
      }

      localStorage.setItem(
        "latitude",
        this.googleAddress.geometry.location.lat()
      );
      localStorage.setItem(
        "longitude",
        this.googleAddress.geometry.location.lng()
      );

      // TODO da sostituire con selezione in base a types[] come da map di google address
      if (this.googleAddress.address_components.length == 8) {
        streetNumber = this.googleAddress.address_components[0].short_name;
        address = this.googleAddress.address_components[1].short_name;
        city = this.googleAddress.address_components[2].short_name;
      } else {
        address = this.googleAddress.address_components[0].short_name;
        city = this.googleAddress.address_components[1].short_name;
      }

      let latitude = localStorage.getItem("latitude");
      let longitude = localStorage.getItem("longitude");
      if (this.googleAddress.address_components.length == 8) {
        postalCode = this.googleAddress.address_components[7].short_name;
      }

      if (typeof this.$route.query.tipo != 'undefined') {
        this.requestType = this.$route.query.tipo;
      }

      window.top.location.href = `https://valuta.newarc.it/configure?tipo=${this.requestType}&address=${address}&streetNumber=${streetNumber}&city=${city}&postalCode=${postalCode}&lat=${latitude}&lng=${longitude}`;
      //window.top.location.href = `https://newarc-staging.web.app/configure?address=${address}&streetNumber=${streetNumber}&city=${city}&postalCode=${postalCode}&lat=${latitude}&lng=${longitude}`; 



    },
    getImgUrl(img) {
      // var images = require.context('../assets/', false, /\.png$/)
      // console.log(img);
      // return '@/assets/img/' + img;
    }
  },
  data() {
    return {
      requestType: 'vendi',
      screen_width: 0,
      placeSelected: false,
      searchResults: null,
      slidesTimeTimerId: 20,
      slides: [
        {
          image: require('@/assets/img/prof-casa.png'),
          title: 'Salvatore Sigismondi, Professione Casa',
          content: '“Il team di Newarc si è dimostrato fin da subito serio e professionale…. L’immobile che avevamo in vendita è stato acquistato in meno di due settimane e rivenduto su carta in meno di un mese con commissioni piene pagate sia in acquisto che rivendita…”'
        },

      ],
      tooltipShow: [false, false, false],
      firstAddress: null,
      // presetAddress: "",
      houseAddress: "",
      fullAddress: null,
      latitude: 0,
      longitude: 0,
      mockMapCoordinates: {
        latitude: 45.0769239,
        longitude: 7.6200728,
      },
      placesPrediction: [],
      delayBeforeSearchTimer: 500
    };
  },
  components: {
    Navbar,
    FooterComponent,
    ArrowRight,
    MapExample,
    InformationVariant,
    Anitmate,
    VueperSlides,
    VueperSlide
  },
};


</script>
