<template>
  <div class="h-full w-full">
    <div v-if="!this.modelValue" v-bind:id="this.mapId" class="relative w-full rounded h-full" data-lat="40.748817"
      data-lng="-73.985428"></div>

    <div v-else v-bind:id="this.mapId" class="relative w-full rounded h-full" v-bind:data-lat="this.modelValue.latitude"
    v-bind:data-lng="this.modelValue.longitude"></div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: Object,
    additionalMarkers: Object, // list of coordinates pairs
    mapId: {
      type: String,
      default: 'map-canvas'
    }
  },

  mounted() {
    
    let google = window.google;
    let map = document.getElementById(this.mapId);

    let lat = map.getAttribute("data-lat");
    let lng = map.getAttribute("data-lng");
    if (this.modelValue) {
      lat = this.modelValue.latitude;
      lng = this.modelValue.longitude;
    }

    const myLatlng = new google.maps.LatLng(lat, lng);
    
    const mapOptions = {
      zoom: 16,
      scrollwheel: false,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      animation: google.maps.Animation.DROP,
      //title: "Hello World!",
      icon: {
        path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
        fillColor: "#489B79",
        fillOpacity: 1,
        strokeColor: "white",
        scale: 1,
      },
    });

    if (this.additionalMarkers != null) {
      for (const additionalMarker of this.additionalMarkers) {
        let additionalMarkerLatlng = new google.maps.LatLng(
          additionalMarker.latitude,
          additionalMarker.longitude
        );
        new google.maps.Marker({
          position: additionalMarkerLatlng,
          map: map,
          animation: google.maps.Animation.DROP,
          //title: "Hello World!",
          icon: {
            path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
            fillColor: "grey",
            fillOpacity: 1,
            strokeColor: "white",
            scale: 1,
          },
        });
      }
    }

    /*const contentString =
      '<div class="info-window-content"><h2></h2>' +
      "<p>A beautiful UI Kit and Admin for Tailwind CSS. It is Free and Open Source.</p></div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });*/
  },
};
</script>
