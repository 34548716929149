<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
            displayFindHow == true ? 'block' : 'hidden'
        ]" >
            
        <div class=" relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center">

            <div class="lg:w-11/12 w-full nap-popup-container flex flex-row items-center justify-center bg-white px-4 py-4 br-10 nap-fh-wrapper">
                <div class="h-full w-full overflow-y-auto overflow-x-hidden relative">
                    <div class="lg:absolute lg:right-0 lg:top-0 cursor-pointer fixed right-4 top-2 z-100 cursor-pointer" @click="toggleFindHow()" style="height:20px;width:20px;">
                        <close fillColor="#919191" />
                    </div>
                    <div class="lg:relative fixed top-0 left-0 w-full bg-white pt-3 lg:py-0">
                        <h3 class="nap-mi-title nap-fh-title mb-4">Cosa rende speciali le nostre case?</h3>
                        <h3 class="nap-mi-sub-title mb-4">{{ selectedDesign }}</h3>
                    </div>
                    
                    
                    <div class="nap-fh-container w-full grid lg:grid-cols-4 grid-cols-2 lg:gap-16 gap-2 lg:px-20 lg:pb-20 pb-10 lg:mt-0 mt-20">
                        
                        <div v-for="findHow in findHowInfo['features']" class="nap-fh-tile flex flex-col justify-start lg:items-start items-center">
                            <img class="nap-fh-tile-image object-cover" :src="require(`@/assets/img/${findHow['image']}`)">
                            <p class="nap-fh-tile-title pre-formatted">{{ findHow['title'] }}</p> 
                            <p class="nap-fh-tile-description">{{ findHow['description'] }}</p> 

                        </div>

                        
                    </div>

                    <div class="lg:px-20">
                        <div class="nap-fh-spc-wrapper relative">
                            <div class="absolute flex flex-row justify-center w-full nap-fh-spc-fixed-title">
                                <h3 class="nap-mi-title mb-4">Vantaggi compresi nel prezzo</h3>
                            </div>
                            
                            
                            <div class="nap-fh-container w-full grid lg:grid-cols-4 grid-cols-2 lg:gap-24 gap-2 ">
                                <div v-for="findHowSpc in findHowInfo['special']" class="nap-fh-spc-tile flex flex-col lg:justify-center justify-start items-center">
                                    <span class="nap-fh-spc-tile-check">&#x2713;</span>
                                    <p class="nap-fh-spc-tile-title">{{ findHowSpc['title'] }}</p> 
                                    <p class="nap-fh-spc-tile-description">{{ findHowSpc['description'] }}</p> 

                                </div>
                            </div>
                            
                        </div>
                    </div>
                    

                    
                    <div class="flex flex-row items-start nap-input-container justify-center">
                        <button class="bg-green text-white font-bold w-full p-3 br-10 nap-fh-button" @click="toggleFindHow()">Chiudi</button>
                    </div>
                    
                    
                </div>
            </div>
            
            
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default{
    props: {
        displayFindHow: {
            type: Boolean,
            required: true
        },
        findHowInfo: {
            type: Object,
            required: true
        },
        
    },
    created(){
        // console.log(this.buildingPlanImages);
    },
    emits: ["toggleFindHow"],
    methods: {
        toggleFindHow() {
            this.$emit("toggleFindHow", "");
        }
    },
    data() {
        return {
            
        }
    },
    components: {
        Close
    }
}

</script>