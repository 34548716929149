<template>
    <div id="id">

        <div v-if="isLoading" class="fixed top-0 left-0 w-full property-loading flex flex-row justify-center items-center">
            <h1 class="text-white f25-ls06">Caricamento...</h1>
        </div>

        <navbar />
        <main class="flex flex-col justify-center relative">

            <div class="lg:hidden fixed w-full top-0 z-50 flex flex-row p-2 items-center nap-config-header-wrapper">
                <button @click="$router.go(-1)"
                    class="nap-config-slider-button br-10 bg-white mr-2 my-auto flex items-center justify-center">
                    &nbsp;
                    <!-- <chevron-left fillColor="#6A6A6A" style="margin-left:-4px;" :size="50"/> -->
                    <img src="@/assets/img/icons/back.svg" height="10" />
                </button>

                <div class="flex flex-row w-full justify-center">

                    <div>
                        <p class="mob-fixed-header-title">
                            {{ property.title }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="lg:hidden w-full flex flex-row items-center">
                <!-- <div class=""> -->
                <button v-if="parseInt(property.timeStatus) < 100" @click="showConfigurePopup()"
                    class="nap-popup-mobile-configure-button button-glow-green mt-20 p-3 mx-2 flex flex-row justify-center items-center lg:hidden block relative">
                    <span class="mr-2 absolute" style="margin-top:-4px; left:20px;">
                        <img src="@/assets/img/icons/white-pencil.png" height="13" />
                    </span>
                    <span>
                        Configura entro il {{ property.formattedEndDate }}
                    </span>

                </button>
                <!-- </div> -->

            </div>



            <div id="slider-container" :class="[
                'na-listing-banner relative h-screen w-full lg:p-0 lg:mt-0 mt-1 mt-20 flex lg:flex-row flex-col'
            ]">

                <!-- autoplay -->
                <vueper-slides v-if="sliderImages.length > 0" ref="vueperslides1"
                    @slide="setSlideIndex($event.currentSlide.index)" :arrows="isMobile == true ? true : false"
                    :bullets="isMobile == true ? false : true" :fixed-height="true"
                    class="relative no-shadow na-vueper-slide">
                    <!-- :fixed-height="false" -->

                    <vueper-slide class="main-slider" v-for="(image, index) in sliderImages " :key="index">
                        <template #content>
                            <div class="nap-slide-image-container flex w-full relative overflow-hidden" :style="{
                                'background': `url('${image}')`
                            }">
                                <!-- <img class="w-full h-full" v-bind:src="`${image}`" alt=""> -->
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>

                <div id="side-bar" class="hidden lg:block lg:w-400 h-full" style="max-height: 900px;">

                    <div class="flex flex-col justify-between h-full">
                        <div>
                            <div class="sidebar-sticky-header flex-none">
                                <div class="flex items-center justify-center sidebar-configure-header">
                                    <h2 class="f18-ls3p font-bold" style="color: #000000">CONFIGURA</h2>
                                </div>

                                <!--<div style="height: 0.09px" class=" bg-gray-200 w-full"></div>

                                <div v-if="parseInt(property.timeStatus) < 100" class="h-20 flex items-center justify-between px-5">
                                    <span class="f18-ls3p font-bold">Personalizza stile?</span>
                                    <div>
                                        <input type="checkbox" v-model="showStyle" class="toggle" id="header-toggle" true-value="true" false-value="false" />
                                        <label class="toggle-label" for="header-toggle"></label>
                                    </div>
                                    
                                </div>
                                <div style="height: 0.09px" class=" bg-gray-200 w-full"></div>-->
                            </div>


                            <div class="slider-sidebar-content" :style="{
                                'padding-bottom': parseInt(property.timeStatus) < 100 ? '' : '140px'
                            }">


                                <div v-if="showStyle == 'true'" class="px-5 mt-10p pb-10p">
                                    <h3 class="f12-ls3p">STILI NEWARC</h3>
                                    <div class="flex overflow-x-auto mt-10p">
                                        <button v-if="configuration.length > 2" class="mr-2"
                                            v-on:click="$refs.vueperslides2.previous()"><img class=""
                                                src="@/assets/img/icons/arrow-left.png" alt=""></button>

                                        <vueper-slides v-if="configuration.length > 0" ref="vueperslides2" :arrows="false"
                                            :bullets="false" :infinite="true" :fixed-height="true"
                                            :visible-slides="configuration.length == 1 ? 1 : 2"
                                            class="relative no-shadow sidebar-slider-slides">
                                            <!-- :fixed-height="false" -->

                                            <vueper-slide class="sidebar-slider"
                                                v-for="(config, config_index) in configuration " :key="index">
                                                <template #content>
                                                    <div
                                                        :class="['flex flex-col w-full relative overflow-hidden sidebar-slide-config-image ', configuration.length > 1 ? 'items-center' : '']">
                                                        <img class="" src="@/assets/img/prop-style-slider.png" alt="">
                                                        <div class="flex flex-col items-center">
                                                            <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                            <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                            }}</span>
                                                        </div>

                                                    </div>
                                                </template>
                                            </vueper-slide>
                                        </vueper-slides>
                                        <button v-if="configuration.length > 2" class="ml-2"
                                            v-on:click="$refs.vueperslides2.next()"><img class=""
                                                src="@/assets/img/icons/arrow-right.png" alt=""></button>
                                    </div>


                                </div>
                                <div v-if="showStyle == 'true'" style="height: 0.09px" class=" bg-gray-200 w-full"></div>
                                <!-- <div style="height: 0.09px" class=" bg-gray-200 w-full"></div>

                                <div class="px-5 py-5">
                                    <h3 class="f12-ls3p">STILI NEWARC</h3>
                                    <div class="flex overflow-x-auto">
                                        <button class="mr-2">Left Arrow</button>
                                        <ul class="flex space-x-4">
                                            <li v-for="i in 2" :key="i">Text {{ i }}</li>
                                        </ul>
                                        <button class="ml-2">Right Arrow</button>
                                    </div>
                                </div> -->



                                <div v-if="showStyle == 'true'" class="px-5 pb-10p">
                                    <h3 class="f12-ls3p mt-10p">MODIFICA TINTA PARETI</h3>
                                    <div class="flex overflow-x-auto mt-10p">
                                        <button v-if="configuration.length > 3" class="mr-2"
                                            v-on:click="$refs.vueperslides3.previous()"><img class=""
                                                src="@/assets/img/icons/arrow-left.png" alt=""></button>

                                        <vueper-slides v-if="configuration.length > 0" ref="vueperslides3" :arrows="false"
                                            :bullets="false" :infinite="true" :fixed-height="true"
                                            :visible-slides="configuration.length == 1 ? 1 : 3"
                                            class="relative no-shadow modification-slider-slides">
                                            <!-- :fixed-height="false" -->

                                            <vueper-slide class="sidebar-modification-slider"
                                                v-for="(config, config_index) in configuration " :key="index">
                                                <template #content>
                                                    <div
                                                        :class="['flex flex-col w-full relative overflow-hidden sidebar-modification-image items-center']">
                                                        <img class="" src="@/assets/img/modification-image.png" alt="">
                                                        <div class="flex flex-col items-center">
                                                            <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                            <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                            }}</span>
                                                        </div>

                                                    </div>
                                                </template>
                                            </vueper-slide>
                                        </vueper-slides>
                                        <button v-if="configuration.length > 3" class="ml-2"
                                            v-on:click="$refs.vueperslides3.next()"><img class=""
                                                src="@/assets/img/icons/arrow-right.png" alt=""></button>
                                    </div>


                                </div>
                                <div v-if="showStyle == 'true'" style="height: 0.09px" class=" bg-gray-200 w-full"></div>




                                <div class="px-5 pb-10p" v-if="configurationOptions.length > 0">
                                    <h3 class="f12-ls3p mt-10p">AGGIUNGI OPTIONAL</h3>



                                    <div class="flex flex-col w-full relative sidebar-slide-optional-style-image"
                                        :style="{}">
                                        <ul class="grid grid-cols-2 gap-4 mt-10p">

                                            <li v-for="(configOption, index) in configurationOptions" :key="index"
                                                @click="selectionConfigurationOption(configOption)" :class="[
                                                    'nap-config-options-list nap-sidebar-config-options items-center justify-start relative',
                                                    configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1 ? 'active' : ''
                                                ]">

                                                <div class="absolute left-2 top-1">
                                                    <input type="checkbox"
                                                        v-bind:class="['na-custom-radio option-input radio', configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) > -1 ? '' : 'hidden']"
                                                        :checked="configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1">
                                                </div>

                                                <div class="nap-sb-config-option-image-container">
                                                    <div class="nap-sb-con-op-image" :style="{
                                                        // 'background': `url('${configOption.image}')`
                                                        'background': 'url(' + require(`@/assets/img/${configOption.title.toLowerCase().replace(' ', '-').concat('.jpg')}`) + ')'
                                                    }">

                                                    </div>

                                                    <h4 class="nap-sb-con-op-title text-green mt-2">{{ configOption.title }}
                                                    </h4>
                                                    <span
                                                        class="nap-sb-con-op-price nap-config-option-price flex items-center">€{{ parseInt(configOption.price).toLocaleString()}}</span>
                                                </div>

                                                <nar-tooltip :id="`randomid${index}`" :width="200" title=""
                                                    :description="configOption.description" position="top">
                                                    <div :id="`randomid${index}`" class="absolute right-2 top-2">
                                                        <svg class="nap-sb-con-op-tooltip text-black" viewBox="0 0 24 24"
                                                            fill="#ECECEC" stroke="#22252B" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round">
                                                            <circle cx="12" cy="12" r="10" />
                                                            <line x1="12" y1="16" x2="12" y2="12" />
                                                            <line x1="12" y1="8" x2="12.01" y2="8" />
                                                            >Dark tooltip
                                                        </svg>
                                                    </div>
                                                </nar-tooltip>

                                            </li>
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="sidebar-footer flex flex-col flex-none">
                            <div class="h-50 w-full bg-gray-200 header-sidebar-progressbar">

                                <div class="na-property-time-slider-container items-center relative w-full lg:w-1/2 bg-white lg:p-1 lg:pt-2 lg:px-4 p-3 br-10 pb-2 lg:shadow-none shadow-lg"
                                    style="margin-bottom: 6px;">
                                    <div class=" text-center timeline-title" style="margin-bottom: -10px">AVANZAMENTO LAVORI
                                    </div>

                                    <div class="flex flex-row justify-between">
                                        <img width="20" src="@/assets/img/icons/house_started.svg">
                                        <img width="20" src="@/assets/img/icons/house_compleated.svg">
                                    </div>
                                    <div class="na-property-time-slider relative flex flex-row items-center ">
                                        <div class="absolute bg-beige w-full z-10 br-5 na-time-slider-all">

                                        </div>
                                        <div class="absolute bg-green z-20 br-5  na-time-slider-current" :style="{
                                            'width': progress.completed + '%'
                                        }">
                                            <nar-tooltip v-if="progress.completed < 100" :id="`todayDate`" width="200"
                                                textAlign="center" title="" :description="property.formattedTodayDate"
                                                position="top">
                                                <div :id="`todayDate`"
                                                    class="absolute identy-icon flex flex-row items-center justify-center bg-green"
                                                    :style="{
                                                        'right': progress.completed == 100 ? '0px' : '-17px'
                                                    }">

                                                    <img src="@/assets/img/icons/hammer.svg" class="object-cover" alt="">
                                                </div>
                                            </nar-tooltip>
                                        </div>
                                    </div>
                                    <div class="relative hidden lg:flex flex-row justify-between items-center pb-2">
                                        <span class="na-time-slider-label">Inizio</span>
                                        <span class="na-time-slider-label">Fine</span>
                                    </div>
                                    <div :class="[
                                        'absolute timeline-slider flex justify-center w-full items-center',
                                        ''
                                    ]" style="margin-left: -15px;">

                                        <div :class="[
                                            parseInt(property.timeStatus) >= 100 ? 'timeline-completed' : 'timeline-in-progress',
                                            'timeline-status px-6']">
                                            <span v-if="parseInt(property.timeStatus) >= 100">
                                                Lavori completati!
                                            </span>
                                            <span v-else>
                                                Configurabile fino al {{ property.formattedEndDate }}
                                            </span>


                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="flex bg-white" style="height: 50px;">
                                <div class="w-1/2  header-slider-price flex flex-col justify-center items-start pl-6">


                                    <span class="total"> {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                                    </span>
                                    <span class="total-psq">{{ parseInt(property['eurpermq']).toLocaleString() }}
                                        €/m²</span>
                                </div>
                                <button @click="showContactPopup()"
                                    class="na-property-item-contanct bg-green text-white flex flex-row items-center w-1/2 text-center justify-center font-bold">
                                    Contattaci
                                    &nbsp;
                                </button>
                                <!--<div
                                    class="w-1/2 header-slider-contact-button flex justify-center items-center bg-green-500 text-white relative"><button @click="showContactPopup()">
                                    <img class="absolute" style="right: 20px;" src="@/assets/img/icons/white-icon-right.png"
                                        height="10" /></button>
                                </div>-->

                            </div>
                        </div>
                    </div>



                </div>

                <div
                    class="na-listing-go-back cursor-pointer top-10 left-10 absolute z-100 hidden lg:flex flex-row items-center py-2 ">
                    <span>
                        <chevron-left fillColor="#fff" :size="25" style="margin-top: 4px;" />
                    </span>
                    <span class="">
                        <a @click="$router.go(-1)">Tutti gli annunci</a>

                    </span>
                </div>

                <div class="na-banner-gradient lg:block hidden absolute top-0 left-0 z-10"></div>

                <div class="na-slider-thumbnails top-0 left-10 absolute z-20 items-center hidden lg:flex flex-col justify-center"
                    @mouseenter="$refs.vueperslides1['pauseAutoplay'](); autoPlaying = false"
                    @mouseleave="$refs.vueperslides1['resumeAutoplay'](); autoPlaying = false">

                    <ul class="na-thumbnail-navigator">
                        <li class="text-center flex flex-col justify-end items-center"
                            @click="slideThumbnailsMargin($refs, -1)">
                            <button>
                                <img style="transform: scaleY(-1);" width="25" src="@/assets/img/icon-down.png" alt="">
                            </button>
                        </li>
                    </ul>
                    <div class="na-slide-thumbnail-container">
                        <ul :style="{
                            marginTop: slideMarginTop + 'px'
                        }">
                            <!-- <li v-bind:class="[
                                'br-5',
                                'shadow-lg',
                                'cur'+slideCurrentIndex,
                                slideCurrentIndex == i ? 'active' : ''
                                ]" 
                                v-for="(image, i) in sliderThumbnail" :key="i" v-on:click="setSlideIndex(i)" >
                                <img class="h-full object-cover br-5" v-bind:src="`${image}`" alt="">
                            </li> -->

                            <li v-bind:class="[
                                'br-5',
                                'cur' + slideCurrentIndex,
                                slideCurrentIndex == i ? 'active' : ''
                            ]" v-for="(image, i) in sliderThumbnail" :key="i"
                                v-on:click="$refs.vueperslides1.goToSlide(i)">
                                <div class="nap-slider-thumbnail-image" :style="{
                                    'background': `url('${image}')`,

                                }">
                                    &nbsp;

                                </div>
                                <!-- <img class="h-full object-cover br-5" v-bind:src="`${image}`" alt=""> -->
                            </li>


                        </ul>
                    </div>


                    <ul class="na-thumbnail-navigator">
                        <li class="text-center" @click="slideThumbnailsMargin($refs, 1)">
                            <button>
                                <img width="25" src="@/assets/img/icon-down.png" alt="">
                            </button>
                        </li>
                    </ul>
                </div>






            </div>

            <div class="property-main-content w-full">

                <div class="container flex flex-row mx-auto">
                    <div class="main-content-left w-full lg:w-2/3 flex-initial p-2">

                        <div class="nap-action-button-container flex flex-row justify-between">

                            <div class="flex flex-row">
                                <button @click="showLayoutPopup()" class="nap-action-button button-planimetria">
                                    <span class="lg:block hidden">Planimetria</span>
                                </button>
                                <button @click="showMapPopup()" class="nap-action-button button-map"><span
                                        class="lg:block hidden">Mappa</span></button>
                                <!--<button class="nap-action-button button-streetview"><span class="lg:block hidden">Street View</span></button>-->
                            </div>
                            <div class="hidden">
                                <button class="nap-export-button px-3">
                                    &nbsp;
                                    <!-- <img src="@/assets/img/icons/share.svg"> -->
                                </button>


                            </div>


                        </div>

                        <div class="nap-section-1">

                            <h3>L'immobile</h3>

                            <div class="nap-section-1-container">
                                <h5>{{ property['title'] }}</h5>
                                <p><strong>{{ property['zone'] + ', ' + property['city'] }}</strong></p>

                                <ul class="the-property-features flex flex-wrap justify-start">

                                    <li v-for="(feature, findex) in property['features']" :class='`icon-${findex}`'>
                                        <span>
                                            {{ feature }}
                                        </span>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div class="nap-section-2 flex lg:flex-row flex-col justify-center lg:justify-end">
                            <div>
                                <p>Una casa Newarc è unica.</p>
                                <!-- <a href="https://www.newarc.it/le-case-newarc/" target="_blank">Scopri perchè ></a> -->
                                <a href="javascript:void(0)" @click="toggleFindHow()">Scopri perchè ></a>
                            </div>
                            <div class="w-full mx-auto lg:hidden block nap-scopri-mobile">
                                &nbsp;
                            </div>
                            <!-- <img src="@/assets/img/why_newarc_home.png" class='w-full mx-auto lg:hidden block'> -->
                        </div>

                        <div class="nap-section-1">

                            <h3>Descrizione</h3>

                            <div class="nap-section-1-container">
                                <p class="pre-formatted">{{ property['description'] }}</p>
                            </div>
                        </div>

                        <div class="nap-section-1">

                            <h3>Caratteristiche</h3>

                            <div class="nap-section-1-container">
                                <ul class="the-property-chars flex flex-wrap justify-start">
                                    <li v-for="characterist in property['characteristics']"
                                        class="flex flex-row items-center">
                                        <img :src="require(`@/assets/img/icons/${characterist.split(' ').join('-').toLowerCase()}.svg`)"
                                            alt="">
                                        <span>
                                            {{ characterist }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="nap-section-1">
                            <div class="flex flex-row justify-between">
                                <h3>Analisi della zona</h3>
                                <button @click="showMapPopup()" class="nap-header-link">
                                    Allarga mappa
                                </button>
                            </div>

                            <div class="nap-section-1-container">


                                <div v-if="isLoading == false" class="w-full" style="height:350px">
                                    <map-listing-page :mapId="id2" v-bind:mapPosition="this.mapPosition" :maxZoom="16" />
                                </div>

                                <div class="nap-eminities">
                                    <ul class="flex flex-row">
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/markets.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['market'] }}</h4>
                                                <p class="w-full lg:block hidden">Supermercati</p>
                                            </div>
                                        </li>
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/schools.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['school'] }}</h4>
                                                <p class="w-full lg:block hidden">Scuole</p>
                                            </div>
                                        </li>
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/parks.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['park'] }}</h4>
                                                <p class="w-full lg:block hidden">Parchi</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- COLLEGARE STA FRASE ALLA ZONA -->
                                <!--<p>San Paolo, zona residenziale ma ricca di servizi e attività di ogni genere. Il centro è
                                    facilmente raggiungibile grazie alla presenza di mezzi pubblici a pochi passi
                                    dall'appartamento.</p>-->

                            </div>
                        </div>

                        <div class="nap-section-1">
                            <div class="flex flex-row justify-between">
                                <h3>Planimetrie</h3>
                                <button @click="showLayoutPopup()" class="nap-header-link">
                                    Allarga planimetrie
                                </button>
                            </div>

                            <div class="nap-plan-slider">
                                <vueper-slides :arrows="true" :bullets="false" :fixed-height="true"
                                    class="no-shadow relative mx-auto" style="height:250px;">
                                    <!-- :fixed-height="false" -->

                                    <vueper-slide v-for="(image, index) in property['picturePaths']" :key="index">
                                        <template #content>
                                            <div class="flex justify-center relative h-full">
                                                <img class="h-full" v-bind:src="`${image}`" alt="">
                                            </div>
                                        </template>
                                    </vueper-slide>
                                </vueper-slides>
                            </div>
                        </div>

                        <div class="nap-section-1">

                            <h3>Esempio di mutuo</h3>

                            <div class="nap-section-1-container">
                                <div class="flex lg:flex-row flex-col lg:justify-evenly items-center flex-col-reverse">
                                    <div class="flex flex-col nap-mortgage-form w-full px-10 lg:mt-0 mt-4">
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Valore immobile</label>
                                            <input class="w-2/3 na-input br-6" v-model="price" type="number">
                                        </div>
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Importo richiesto</label>
                                            <input class="w-2/3 na-input br-6" v-model="ltv" type="number">
                                        </div>
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Durata mutuo</label>
                                            <select v-model="mort_duration" class="w-2/3 na-select br-6">
                                                <option value="25">25 Anni</option>
                                                <option value="20">20 Anni</option>
                                                <option value="15">15 Anni</option>
                                            </select>
                                        </div>
                                        <div class="flex">
                                            <button class="w-full bg-green text-white br-6 f15-ls043 font-bold p-3"
                                                @click="showContactPopup()">Chiedi un preventivo gratuito</button>
                                        </div>
                                    </div>

                                    <div class="nap-mortgage-details w-full flex flex-col px-10">
                                        <span class="nap-mortgage-rate">Tasso del mutuo <strong>3,5%</strong></span>

                                        <div class="circle-border mx-auto">
                                            <div class="circle flex flex-row items-center justify-center">
                                                <span class="nap-mortgage-value">
                                                    €<strong>{{ (((ltv * 2) * 0.035) / mort_duration).toFixed(0) }}</strong>

                                                </span>

                                            </div>
                                        </div>

                                        <div class="flex flex-row justify-between nap-mortgage-ratio mt-4">
                                            <span class="flex flex-row justify-start items-center">
                                                <span class="nap-bullet"></span>
                                                Anticipo (22,4%)
                                            </span>

                                            <span class="flex flex-row justify-start items-center">
                                                <span class="nap-bullet active"></span>
                                                Mutuo (77,6%)
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <!-- Sidebar -->
                    <div class="property-sidebar w-1/3 lg:block hidden">

                        <div id="sticky-anchor"></div>
                        <div id="sticky">
                            <div class="nap-property-sidebar">
                                <div style="padding-left: 27px;padding-right: 27px;">
                                    <h2 class="nap-sidebar-title">LA TUA NUOVA CASA</h2>
                                    <ul class="nap-sb-options">

                                        <li v-if="configuration.length > 0" class="flex flex-col w-full nap-sb-main-li"
                                            style="border-bottom:none;">

                                            <div class="flex flex-row justify-between nap-sidebar-style-header">
                                                <p class="nap-sidebar-style-item-title">Prezzo base</p>
                                                <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                                    parseInt(configuration[configurationSelected]['price']).toLocaleString()
                                                }}</p>
                                            </div>
                                            <!--<div class="flex flex-row pr-3">
                                                    <div :class="[
                                                        'na-feature-item-image'

                                                    ]" :style="{
                                                        'background': 'url(' + require(`@/assets/img/${configuration[configurationSelected]['title'].toLowerCase()}-cover.jpg`) + ')',
                                                        // 'background': `url(${configuration[configurationSelected]['images'][0]})`,

                                                    }">
                                                </div>
                                                <div class="na-item-configure-features flex flex-col justify-between">
                                                    <div class="flex flex-col justify-between">
                                                        <div class="flex flex-col">
                                                            <small
                                                                v-if="configuration[configurationSelected].is_predefined == 1"
                                                                class="configure-item-sub-title text-left">PREDEFINITO</small>

                                                            <span class="configure-item-title text-left">{{
                                                                configuration[configurationSelected]['title'] }}</span>
                                                        </div>
                                                        <ul class="na-configure-features">
                                                            <li  v-for="detail in configuration[configurationSelected]['details']" :key="detail" class="pre-formatted">
                                                                <div v-if="detail!=''">- {{ detail }}</div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <a class="na-configure-item-link" href="javascript:void(0)">Maggiori
                                                        informazioni</a>
                                                </div>
                                            </div>-->


                                        </li>

                                    </ul>
                                    <div class="nap-sb-options" v-if="configurationOptionSelected.length > 0">
                                        <div class="flex flex-row justify-between nap-sidebar-style-header">
                                            <p class="nap-sidebar-style-item-title">Optional</p>
                                            <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                                parseInt(optionalTotal).toLocaleString() }}</p>
                                        </div>

                                        <div class="flex flex-col">

                                            <div v-for="options in configurationOptionSelected"
                                                class="flex flex-row justify-between nap-sidebar-optional-row">
                                                <span class="nap-bs-item-title">{{ options.title }}</span>
                                                <span class="nap-bs-item-price">{{ property.currency }}{{
                                                    parseInt(options.price).toLocaleString() }}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="nap-sb-grand-total flex flex-row justify-between items-cener">
                                        <p class="nap-gt-title">Totale</p>
                                        <p class="nap-gt flex flex-col items-end">
                                            <span class="nap-gt-p">
                                                {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                                            </span>
                                            <span class="nap-gt-s">
                                                {{ parseInt(property['eurpermq']).toLocaleString() }} €/m²
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div class="flex flex-row w-full justify-evenly items-center na-property-item-action">
                                    <div @click="scrollToTop()"
                                        class="nap-sb-config-button flex flex-row text-center w-full justify-evenly items-center cursor-pointer">
                                        <!-- <span>
                                            <brush-variant fillColor="#949494" :size="30" />
                                        </span> -->
                                        <img src="@/assets/img/icons/modification-brush.png">

                                        <span class="flex flex-col items-start">
                                            <span>Modifica</span>
                                            <span>configurazione</span>
                                        </span>
                                    </div>
                                    <button
                                        class="na-property-item-contanct bg-green text-white flex flex-row items-center w-full text-center justify-center font-bold"
                                        @click="showContactPopup()">
                                        Contattaci
                                        &nbsp;
                                        <!-- <arrow-right fillColor="#fff" style="margin-top: 4px;"  /> -->
                                    </button>
                                </div>
                            </div>

                            <p class="nap-visit-history">Altre 5 persone hanno aperto l'annuncio oggi!</p>
                        </div>
                    </div>
                </div>

            </div>

            <div :class="[
                'nap-mobile-bottom-contact-button fixed bottom-0 lg:hidden flex flex-row w-full items-center justify-between'
            ]">
                <button class="bt-configure flex flex-row justify-center items-center br-10 flex-1"
                    @click="showConfigurePopup">
                    <img src="@/assets/img/icons/white-pencil.png" height="13" /> &nbsp;&nbsp;
                    Configura
                    <!-- <pencil-outline fillColor="#000000" /> -->
                </button>
                <button class="relative bt-contact flex flex-row justify-center items-center br-10 flex-1"
                    @click="showContactPopup">
                    Contattaci &nbsp;&nbsp;

                    <!-- <img src="@/assets/img/icons/arrow-right.svg" width="17" /> -->
                    <!-- <img src="@/assets/img/icons/contact.svg" width="17" /> -->
                </button>

            </div>
            <div :class="[
                'nap-mobile-bottom-sheet fixed lg:hidden flex flex-col w-full items-center',
                isBottomExpanded ? 'expanded' : ''
            ]">

                <div class="nap-bs-header w-full flex flex-row justify-center items-center" @click="toggleBottomSheet">
                    <span class="nap-bs-title flex items-center">
                        La tua configurazione:  <span>{{ ' ' }}{{ property.currency }}{{ grandTotal != null ? parseInt(grandTotal).toLocaleString() : '0.00' }}</span>
                    </span>
                    &nbsp;&nbsp;
                    <span class="nap-bs-icon">
                        <span class="nap-bs-collapse">
                            <chevron-down fillColor="#fff" size="26" />
                        </span>
                        <span class="nap-bs-expand">
                            <chevron-up fillColor="#000" size="26" />
                        </span>
                    </span>

                </div>

                <div class="nap-bs-item-wrapper flex flex-col justify-start w-full">
                    <div class="nap-bs-item-wrap flex flex-col w-full h-full">
                        <!-- nap-bs-item-wrap flex flex-col w-full h-full justify-between -->
                        <div class="nap-bs-item-container">
                            <h5>STILE</h5>
                            <ul>
                                <li class="flex flex-row justify-between items-center">
                                    <!--<span class="nap-bs-item-title">{{ configuration.length > 0 ?
                                        configuration[configurationSelected].title : '' }}</span>-->
                                    <span class="nap-bs-item-title"> Configurazione base </span>
                                    <span class="nap-bs-item-price">
                                        {{ property['currency'] }}
                                        {{ configuration.length > 0 ? parseInt(property['price']).toLocaleString() : '' }}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="nap-bs-item-container" v-if="configurationOptions.length > 0">
                            <h5>OPTIONAL</h5>
                            <ul>
                                <li v-if="configurationOptionSelected.length > 0"
                                    v-for="options in configurationOptionSelected"
                                    class="flex flex-row justify-between items-center">
                                    <span class="nap-bs-item-title">{{ options.title }}</span>
                                    <span class="nap-bs-item-price">{{ property.currency }}{{
                                        parseInt(options.price).toLocaleString() }}</span>
                                </li>
                                <li v-else>
                                    <span class="nap-bs-item-title">Nessun optional selezionato</span>
                                </li>
                                <!-- <li class="flex flex-row justify-between items-center">
                                    <span class="nap-bs-item-title">Antifurto Verisure</span>
                                    <span class="nap-bs-item-price">+ €0</span>
                                </li>
                                <li class="flex flex-row justify-between items-center">
                                    <span class="nap-bs-item-title">Climatizzatore</span>
                                    <span class="nap-bs-item-price">+ €0</span>
                                </li> -->
                            </ul>
                        </div>

                        <div class="nap-bs-item-container">
                            <h5>LA TUA NUOVA CASA</h5>

                            <span class="nap-bs-price">{{ property['currency'] }}
                                {{ configurationSelected != null ? parseInt(grandTotal).toLocaleString() : '0.00' }} </span>
                        </div>
                    </div>

                </div>



            </div>

        </main>
        <footer id="footer">

            <div class="container nap-footer">

                <div class="flex flex-col justify-center items-center">
                    <h2>Vorresti sapere di più su questo immobile?</h2>
                    <p>Ti richiamiamo noi!</p>
                    <button class="px-14 py-4" @click="showContactPopup">Contattaci</button>
                    <a style="cursor: pointer;" @click="$router.go(-1)">&lt; Torna agli annunci</a>
                </div>

            </div>

        </footer>

        <!-- Map popup -->

        <mobile-popup-map v-if="mapPosition.property.length > 0" v-bind:showMapPop="showMapPop"
            v-bind:mapPosition="mapPosition" @hideMapPopup="hideMapPopup" />

        <!-- contact popup desktop -->
        <popup-contact v-bind:showContactPop="showContactPop" @hideContactPopup="hideContactPopup" />

        <mobile-popup-configuration ref="napmobileconfiguratordiv" v-bind:progress="progress" v-bind:property="property"
            v-bind:showConfigPop="showConfigPop" v-bind:configuration="configuration"
            v-bind:configurationOptionSelected="configurationOptionSelected" v-bind:sliderImages="sliderImages"
            v-bind:configurationOptions="configurationOptions" v-bind:configurationSelected="configurationSelected"
            v-bind:price="price" v-bind:grandTotal="grandTotal" @toggleStyleMoreInfo="toggleStyleMoreInfo"
            @hideConfigurePopup="hideConfigurePopup" @updateSliderImages="updateSliderImages"
            @selectionConfigurationOption="selectionConfigurationOption" @setSlideIndex="setSlideIndex"
            @updateConfigurationSelection="updateConfigurationSelection" />

        <mobile-popup-layout v-bind:showLayoutPop="showLayoutPop" v-bind:buildingPlanImages="property['picturePaths']"
            @hideLayoutPopup="hideLayoutPopup" />


        <style-more-info v-bind:displayStyleMoreInfo="displayStyleMoreInfo" v-bind:selectedDesign="selectedDesign"
            v-bind:styleMoreInfo="styleMoreInfo" @toggleStyleMoreInfo="toggleStyleMoreInfo" />

        <popup-find-how v-bind:displayFindHow="displayFindHow" v-bind:findHowInfo="findHowInfo"
            @toggleFindHow="toggleFindHow" />

    </div>
</template>

<script>
import Navbar from "@/components/Navbars/ListingNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";
import Check from "vue-material-design-icons/Check.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import ExportVariant from "vue-material-design-icons/ExportVariant.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Close from "vue-material-design-icons/Close.vue";
import PencilOutline from "vue-material-design-icons/PencilOutline.vue";
import SendOutline from "vue-material-design-icons/SendOutline.vue";
import BrushVariant from "vue-material-design-icons/BrushVariant.vue";
import { createPopper } from "@popperjs/core";

import MobilePopupConfiguration from "./Property/MobilePopupConfiguration.vue";
import StyleMoreInfo from "./Property/StyleMoreInfo.vue";
import MobilePopupLayout from "./Property/MobilePopupLayout.vue";
import PopupContact from "./Property/PopupContact.vue";
import PopupFindHow from "./Property/PopupFindHow.vue";
import MobilePopupMap from "./Property/MobilePopupMap.vue";
import NarTooltip from "../components/utils/Tooltip/NarTooltip.vue";

// import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapListingPage from "@/components/Maps/MapListingPage.vue";
// import { createPopper } from "@popperjs/core";
// import {Anitmate} from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';
import {

    ref,
    getDownloadURL,
    getStorage
} from 'firebase/storage';


import { functions, storage } from '../firebase/init.js';
import { httpsCallable } from 'firebase/functions';
import firebase from 'firebase/compat/app';


export default {

    props:
    {
        docId: { required: true }
        // property: { required: true}
    },
    mounted() {
        this.getNewarcHome();

        this.sidebarWidth = document.getElementById("sticky").offsetWidth;
        // console.log(window.outerWidth);
        if (window.outerWidth > 600) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }

        this.addBodyOverflowHidden();


    },
    unmounted() {
        this.removeBodyOverflowHidden();
    },
    created() {
        window.addEventListener('scroll', this.stickySidebar);
    },
    destroyed() {
        window.removeEventListener('scroll', this.stickySidebar);
    },
    updated() {

    },
    methods: {

        handleMobilePopupScroll(e) {
            console.log(e);
        },

        scrollToTop() {
            // window.scrollTo(0, 0);
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                    clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 20)
        },
        async getNewarcHome() {

            const db = firebase.firestore();

            const newarcHomesRef = db.collection("newarcHomes").doc(this.docId);
            let result = {};
            try {
                const doc = await newarcHomesRef.get();
                if (doc.exists) {
                    result = await doc.data();
                } else {
                    //console.error(`No such document with ID ${this.docId}`);
                    return;
                }
            } catch (error) {
                console.error(error);
            }

            /* Get the Rooms to build the title of the property */
            let rooms = '';
            switch (parseInt(result['locals'])) {
                case 1:
                    rooms = 'Monolocale'
                    break;
                case 2:
                    rooms = 'Bilocale'
                    break;
                case 3:
                    rooms = 'Trilocale'
                    break;
                case 4:
                    rooms = 'Quadrilocale'
                    break;
                default:
                    rooms = 'Plurilocale'
                    break;
            }

            /* Set the features for the tile on the page */
            let features = {};

            if (result['mq'] != "") {
                features['superficie'] = result['mq'] + ' mq';
            }
            if (result['baths'] != "") {
                features['bagni'] = result['baths'] + ' bagni';
            }
            if (rooms != "") {
                features['locali'] = rooms;
            }
            if (result['floors'] != "") {
                features['piano'] = result['floors'] + ' piano';
            }

            /*if( result['bedrooms'] != "" ) {
                features['locali'] = result['bedrooms'] + ' locali';
            }*/

            /* Property features. The icon of the feautre is stored in the assets.
            * The icon name is the lowercase and replace any space with - of the feature name
            */
            let characteristics = result['propertyFeatures'];

            /* Set initial price of the property to 0 */
            let price = '€000';
            let eurpermq = '€000';

            /* Fetch Network url of the image from Firebase storage */
            if (result['picturePaths'] != null && result['picturePaths'].length > 0) {
                for (let imgIndex = 0; imgIndex < result['picturePaths'].length; imgIndex++) {
                    this.getImageUrlFromFS(imgIndex, result['firebaseId'], result['picturePaths'][imgIndex]);
                }
            }

            /* Preset a Style for the user. It will be either the Default from the DB if default
            * not found then it will be one of the styles
            */
            let predefinedIndex = 0;
            this.configurationSelected = 0;

            /* Arrange the style and price of the styles */
            if (result['styles'].length > 0) {

                price = parseInt(result['styles'][0]['price']);
                eurpermq = price / parseInt(result['mq']);

                for (let sIndex = 0; sIndex < result['styles'].length; sIndex++) {

                    let description = result['styles'][sIndex]['description'];
                    this.configuration[sIndex] = {
                        'id': sIndex,
                        'is_predefined': result['styles'][sIndex]['isDefault'],
                        'title': result['styles'][sIndex]['styleName'],
                        'picturePaths': result['styles'][sIndex]['picturePaths'],
                        'details': description.split('-'),
                        'price': result['styles'][sIndex]['price'],
                        'images': []
                    };

                    /* Reassign the price of the property if found a default style from DB */
                    if (parseInt(result['styles'][sIndex]['isDefault']) == 1) {

                        predefinedIndex = sIndex;
                        price = parseInt(result['styles'][sIndex]['price']);

                        /* Calculate the price per SQM */
                        eurpermq = price / parseInt(result['mq']);

                        /* Set the index of the selected configurate to the Default style index */
                        this.configurationSelected = sIndex;
                    }


                    /* Fetch Network url of the style image from Firebase storage */
                    if (this.configuration[sIndex]['picturePaths'].length > 0) {
                        for (let imgIndex = 0; imgIndex < this.configuration[sIndex]['picturePaths'].length; imgIndex++) {
                            this.getStyleImageUrlFromFS(sIndex, imgIndex, result['firebaseId'], this.configuration[sIndex]['picturePaths'][imgIndex]);

                        }
                    }

                }

                /* Set images of default style as the slider images and their thumnails */
                this.sliderImages = this.configuration[predefinedIndex]['images'];
                this.sliderThumbnail = this.configuration[predefinedIndex]['images'];

            }

            /* Format the Start, End and Deadline dates for the Slider on the banner tooltip */
            let sDate = new Date(parseInt(result['startDate']));
            let startDateFormatted = sDate.getDate().toString() + '/' + (sDate.getMonth() + 1).toString() + '/' + sDate.getFullYear().toString();

            let lDate = new Date(parseInt(result['limitDate']));
            let limitDateFormatted = lDate.getDate().toString() + '/' + (lDate.getMonth() + 1).toString() + '/' + lDate.getFullYear().toString();

            let eDate = new Date(parseInt(result['endDate']));
            let endDateFormatted = eDate.getDate().toString() + '/' + (eDate.getMonth() + 1).toString() + '/' + eDate.getFullYear().toString();

            let tDate = new Date();
            let todayDateFormatted = tDate.getDate().toString() + '/' + (tDate.getMonth() + 1).toString() + '/' + tDate.getFullYear().toString();

            /* Calculate the Start, End and Deadline dates for the Slider on the banner Timeline */
            let startDate = new Date(parseInt(result['startDate'])).getTime();
            let today = new Date().getTime();
            let limitDate = new Date(parseInt(result['limitDate'])).getTime();
            let endDate = new Date(parseInt(result['endDate'])).getTime();

            let startToTodayDifference = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
            let startToLimitDifference = Math.floor((limitDate - startDate) / (1000 * 60 * 60 * 24));
            let startToEndDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

            let deadline = (startToLimitDifference / startToEndDifference) * 100;
            this.progress['deadline'] = deadline > 100 ? '100' : deadline;

            let completed = (startToTodayDifference / startToEndDifference) * 100;
            this.progress['completed'] = completed > 100 ? '100' : completed;

            /* Number of days to reach the deadline. */
            this.daysLeftToComplete = startToEndDifference;

            /* Split the description with \n to display a line break on the page. */
            let descp = result['description']; //.split("\n");

            /* Generate the Property title based on the Rooms */
            let propertyTitle = '';
            if (result['addressComponents'] != null) {
                propertyTitle = rooms + ' in ' + result['addressComponents']['street'] + ' ' + result['addressComponents']['civico'];
            }

            /* Property variables */
            this.property = {
                'currency': '€',
                'docId': result['firebaseId'],
                'insertTimestamp': result['insertTimestamp'],
                'title': propertyTitle,
                'description': result['description'],
                'descp': descp,
                'location': result['location'],
                'zone': result['zone'],
                'city': result['city'],
                'size': result['mq'],
                'price': price,
                'eurpermq': eurpermq,
                'features': features,
                'images': result['picturePaths'] ?? [],
                'picturePaths': [],
                'phone': '',
                'share': '',
                'characteristics': characteristics,
                'startDate': result['startDate'],
                'endDate': result['endDate'],
                'limitDate': result['limitDate'],
                'formattedStartDate': startDateFormatted,
                'formattedEndDate': endDateFormatted,
                'formattedLimitDate': limitDateFormatted,
                'formattedTodayDate': todayDateFormatted,
                'timeStatus': completed,
                'amenities': result['picturePaths']
            };

            /* Setting Optional */
            if (typeof result['optional'] !== 'undefined') {
                this.configurationOptions = result['optional'];
            } else {
                this.configurationOptions = [];
            }

            /* Store the location of the property to display on the map */
            if (typeof result['location'] != 'undefined') {
                this.mapPosition['property'].push(result['location']);
            }

            let amenities_limit = 10;

            /* Store the amenites to show on the Ameneties map on the page */
            if (typeof result['amenities'] != 'undefined') {
                if (result['amenities'].length > 0) {
                    for (let ami = 0; ami < result['amenities'].length; ami++) {

                        if (Object.keys(result['amenities'][ami]).length > 0) {
                            Object.keys(result['amenities'][ami]).forEach(ame_name => {

                                this.mapPosition[ame_name] = [];

                                var amenities_chunk = result['amenities'][ami][ame_name].splice(0, amenities_limit);
                                this.amenitiesCount[ame_name] = amenities_chunk.length + "" + (result['amenities'][ami][ame_name].length > amenities_limit ? '+' : '');

                                Object.keys(amenities_chunk).forEach(ame_item => {
                                    let loc = amenities_chunk[ame_item];
                                    this.mapPosition[ame_name].push(loc['location']);

                                });
                            });

                        }
                    }
                }

                /* Set the count of the amenites */
                // this.amenitiesCount = result['amenitiesCount'];

            }

            /* Update the price on the calculator */
            this.updatePrice();

            this.isLoading = false;

            /* Add scrolling to the body after the property is read to display */
            this.removeBodyOverflowHidden();

            console.log('herel', result, this.configuration);


        },

        toggleBottomSheet() {
            // console.log('BS');
            this.isBottomExpanded = !this.isBottomExpanded;

            if( this.isBottomExpanded == true ) {
                this.addBodyOverflowHidden();
            } else {
                this.removeBodyOverflowHidden();
            }
        },

        toggleStyleMoreInfo(selectedStyleMoreInfo) {
            this.selectedDesign = selectedStyleMoreInfo;
            this.displayStyleMoreInfo = !this.displayStyleMoreInfo;
        },

        getStyleImageUrlFromFS(sIndex, imageIndex, docId, image) {
            const storage = getStorage();
            const url = getDownloadURL(ref(storage, `newarcHomes/${docId.trim()}/${image.trim()}`))
                .then((url) => {
                    this.configuration[sIndex]['images'][imageIndex] = url;
                    return url;

                })
                .catch((error) => {
                    // Handle any errors
                });
            return url;
        },
        getImageUrlFromFS(imageIndex, docId, image) {

            const storage = getStorage();
            const url = getDownloadURL(ref(storage, `newarcHomes/${docId.trim()}/${image.trim()}`))
                .then((url) => {
                    this.property['picturePaths'][imageIndex] = url;
                    return url;

                })
                .catch((error) => {
                    // Handle any errors
                });
            return url;

        },
        stickySidebar(e) {
            
            
            if (window.innerWidth < 600) return;

            var window_top = window.scrollY;
            var footer_top = document.getElementById("footer").offsetTop;
            var div_top = document.getElementById('sticky-anchor').offsetTop;
            var div_height = document.getElementById("sticky").offsetHeight;

            if (window_top + div_height > (footer_top + 100)) {
                //document.getElementById("sticky").classList.remove('stick');    
            } else if (window_top > (div_top + 30)) {
                document.getElementById("sticky").style.width = this.sidebarWidth + 'px';
                document.getElementById("sticky").classList.add('stick');
            } else {
                document.getElementById("sticky").classList.remove('stick');
            }
            

            
        },


        setSlideIndex(index) {
            this.slideCurrentIndex = index;
            this.currentSlide = index;
            // this.slideThumbnailsMargin(index);
            if (this.slideCurrentIndex > 3) {
                this.slideMarginTop = ((this.slideCurrentIndex - 3) * 70) * (-1);
            } else {
                this.slideMarginTop = 0;
            }
        },
        slideThumbnailsMargin($refs, direction) {
            // console.log(index);
            this.slideCurrentIndex = this.slideCurrentIndex + (direction);
            this.$refs.vueperslides1.goToSlide(this.slideCurrentIndex);
            if (this.slideCurrentIndex > 3) {
                this.slideMarginTop = ((this.slideCurrentIndex - 3) * 70) * (-1);
            } else {
                this.slideMarginTop = 0;
            }

            if (this.sliderThumbnail.length < this.slideCurrentIndex) {
                this.slideCurrentIndex = 0;
            }
        },
        showNextConfigureOnMobile() {

            if (this.mobileConfigureListIndex == (Object.keys(this.configuration).length - 1)) {
                this.mobileConfigureListIndex = 0;
            } else {
                this.mobileConfigureListIndex++;
            }
        },
        addBodyOverflowHidden() {
            document.body.classList.add('overflow-hidden');
        },
        removeBodyOverflowHidden() {
            document.body.classList.remove('overflow-hidden');
        },
        showMapPopup() {
            this.addBodyOverflowHidden();
            this.showMapPop = true;
        },
        hideMapPopup() {
            this.removeBodyOverflowHidden();
            this.showMapPop = false;
        },
        showLayoutPopup() {
            this.addBodyOverflowHidden();
            this.showLayoutPop = true;
        },
        hideLayoutPopup() {
            this.removeBodyOverflowHidden();
            this.showLayoutPop = false;
        },
        showContactPopup() {
            this.addBodyOverflowHidden();
            this.showContactPop = true;
        },
        hideContactPopup() {
            this.removeBodyOverflowHidden();
            this.showContactPop = false;
        },

        showConfigurePopup() {
            this.addBodyOverflowHidden();
            this.showConfigPop = true;
        },
        hideConfigurePopup() {
            this.removeBodyOverflowHidden();
            this.showConfigPop = false;
        },

        toggleFindHow() {

            this.displayFindHow = !this.displayFindHow;

            if (this.displayFindHow == true) {
                this.addBodyOverflowHidden();
            } else {
                this.removeBodyOverflowHidden();
            }

        },


        selectionConfigurationOption(configOption) {
            // console.log(configOption);
            if (this.configurationOptionSelected.indexOf(configOption) == -1) {
                this.configurationOptionSelected.push(configOption);
            } else {
                this.configurationOptionSelected.splice(this.configurationOptionSelected.indexOf(configOption), 1);
            }
            // console.log(this.configurationOptionSelected);
            this.updatePrice();
        },
        updateConfigurationSelection(configname) {
            this.configurationSelected = configname;

        },
        updateSliderImages(configKey) {

            this.sliderImages = this.configuration[configKey]['images'];
            this.sliderThumbnail = this.configuration[configKey]['images'];
            this.configurationSelected = configKey;

            // let price = parseInt(this.configuration[configKey.toString()]['price']);
            // let eurpermq = price / parseInt(this.property['size']);

            // this.property['price'] = price;
            // this.property['eurpermq'] = parseInt(eurpermq);

            // this.ltv = price * 0.20;
            // this.price = price;
            // this.mortgage = (((this.ltv * 2) * 0.025)/this.mort_duration).toFixed(0);
            this.updatePrice();

        },

        updatePrice() {

            console.log(this.configurationSelected);

            let price = parseInt(this.configuration[this.configurationSelected.toString()]['price']);

            this.property['price'] = price;

            this.ltv = (price * 0.20)//(price * 0.20).toLocaleString();
            this.mortgage = (((this.ltv * 2) * 0.025) / this.mort_duration).toFixed(0);
            let optional = 0;

            if (this.configurationOptionSelected.length > 0) {

                for (let configOptionIndex = 0; configOptionIndex < this.configurationOptionSelected.length; configOptionIndex++) {

                    let element = this.configurationOptionSelected[configOptionIndex];
                    // console.log(element, element['price']);

                    price += parseInt(element['price']);
                    optional += parseInt(element['price']);
                }

            }

            console.log(price, optional);

            this.optionalTotal = optional;

            // console.log(price);
            this.price = price//price.toLocaleString();
            this.grandTotal = price;
            let eurpermq = price / parseInt(this.property['size']);
            this.property['eurpermq'] = parseInt(eurpermq);

        }
    },
    data() {
        return {
            showStyle: false,
            displayStyleMoreInfo: false,
            displayFindHow: false,
            isMobile: false,
            isBottomExpanded: false,
            isLoading: true,
            property: {},
            popupMap: 'map-desktop-popup',
            id2: 'map-desktop',
            showConfigPop: false,
            showMapPop: false,
            showLayoutPop: false,
            showContactPop: false,
            price: null,
            grandTotal: 0,
            optionalTotal: 0,
            ltv: null,
            mort_duration: 25,
            daysLeftToComplete: 0,
            progress: {
                'completed': 50,
                'deadline': 95
            },
            mobileConfigureListIndex: 0,
            configurationSelected: 0,
            configuration: [],
            configurationOptionSelected: [],
            configurationOptions: [
                {
                    'image': 'climatizzatore.jpg',
                    'title': 'Condizionatore',
                    'price': '1399',
                    'help': 'Climatizzatore Ariston Alys Plus R-32 trial split, Potenza 9000 btu, wifi, 12 velocità'
                },
                {
                    'image': 'antifurto.jpg',
                    'title': 'Antifurto',
                    'price': '249',
                    'help': 'Pannello di controllo, Sensori volumetrici con fotocamera, Cartelli dissuasori, Sirena, Sensori porte e finestre, App e lettore chiavi, Fumogeno zero vision'
                },
                {
                    'image': 'assicurazione.jpg',
                    'title': 'Assicurazione casa',
                    'price': '599',
                    'help': 'Incendio e scoppio, Eventi naturali, Arredi e contenuto, Furto'
                },
                {
                    'image': 'cucina.jpg',
                    'title': 'Cucina',
                    'price': '7899',
                    'help': 'Cucina ArTre modello Up Design in composizione lineare 425x200 con isola frontale. Colorazione bianco opaco con top marmo. Inclusi nel prezzo poker di elettrodomestici (fuochi, forno, frigo e lavastoviglie) Hotpoint Ariston.'
                }
            ],
            sidebarWidth: 0,
            slideCurrentIndex: 0,
            slideMarginTop: 0,
            tmpIndex: 0,
            testing: 'Hello world!',
            sliderImages: [
                // 'single-banner-image.png',
                // 'single-banner-image.png',
                // 'single-banner-image.png',
                // 'single-banner-image.png',
                // 'single-banner-image.png'
            ],
            sliderThumbnail: [
                // 'slider-thumnail.png',
                // 'slider-thumnail.png',
                // 'slider-thumnail.png',
                // 'slider-thumnail.png',
                // 'slider-thumnail.png'
            ],
            buildingPlanImages: [
                'nap-plan.png',
                'nap-plan.png',
                'nap-plan.png'
            ],
            selectedDesign: "",
            styleMoreInfo: {
                'Stoccolma': [
                    {
                        'title': 'Tinta pareti',
                        'description': 'Colore: Beige, \nRAL: 1014',
                        'image': 'stoccolma1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'stoccolma2.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'stoccolma3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'stoccolma4.jpg'
                    }
                ],
                'Londra': [
                    {
                        'title': 'Tinta pareti',
                        'description': 'Colore: Beige, RAL: 1014',
                        'image': 'londra1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'londra1.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'parigi3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'stoccolma4.jpg'
                    }
                ],
                'Parigi': [
                    {
                        'title': 'Tinta pareti',
                        'description': "Colore: Beige, RAL: 1014",
                        'image': 'parigi1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'parigi2.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'parigi3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'parigi4.jpg'
                    }

                ]
            },
            findHowInfo: {
                'features':
                    [
                        {
                            'title': "Trasformazione \ndegli ambienti",
                            'description': 'I nostri professionisti ricercano la soluzione migliore per ogni casa rimodulando gli spazi affinché siano luminosi, eleganti ed accoglienti.',
                            'image': 'trasformazione-degli-ambienti.jpeg'
                        },
                        {
                            'title': 'Case \nintelligenti',
                            'description': 'Le case Newarc sono dotate di domotica smart per controllare luci e tapparelle con lo smartphone o con gli assistenti vocali.',
                            'image': 'case-intelligenti.jpeg'
                        },
                        {
                            'title': 'Installazioni \ncertificate',
                            'description': 'Gli impianti elettrici, idrici e a gas sono sempre nuovi e certificati. Ci avvaliamo solo di installatori professionali con anni di esperienza.',
                            'image': 'installazioni-certificate.jpeg'
                        },
                        {
                            'title': 'Miglioramento \nclasse energetica',
                            'description': 'I lavori di ristrutturazione eseguiti nelle nostre case permettono di migliorare l’efficienza energetica e di abbattere le emissioni.',
                            'image': 'miglioramento-classe-energetica.jpeg'
                        }
                    ],
                'special': [
                    {
                        'title': '2 anni di garanzia',
                        'description': 'Al termine della ristrutturazione, avrai 2 anni di assicurazione e riparazione gratuiti.',
                    },
                    {
                        'title': 'Assistenza dedicata',
                        'description': 'Il nostro team sarà a tua completa disposizione per ogni esigenza.',
                    },
                    {
                        'title': 'Personalizzazioni',
                        'description': 'Possibilità di modificare i materiali da capitolato e di scegliere diversi tipi di tinta.',
                    },
                    {
                        'title': 'Interior Design',
                        'description': 'Realizzazione di un progetto personalizzato con un interior designer partner.',
                    }
                ]
            },
            amenitiesCount: {
                school: 0,
                market: 0,
                park: 0

            },
            mapPosition: {
                moveToRight: false,
                showPopup: false,
                center: {
                    lat: 45.072883,
                    lng: 7.666794
                },
                property: [],
                // schools: [],
                // supermarket: [
                //     // {
                //     //     lat: 45.072013, lng: 7.664136
                //     // },
                //     // {
                //     //     lat: 45.073012, lng: 7.663528
                //     // }
                // ],
                // park: [
                //     // { lat: 45.073052, lng: 7.668724 },
                //     // { lat: 45.075365, lng: 7.666859 }
                // ]

                // {
                // lat: 45.091379, 
                // lng:7.679094
                // },
                // {
                // lat: 45.094397,
                // lng:7.682490
                // },
                // {
                // lat: 45.093421,
                // lng:7.673938
                // }
            }
        }
    },
    beforeMount() {
        window.scrollTo(0, 0);
    },
    components: {
        Navbar,
        FooterComponent,
        ArrowLeft,
        ArrowRight,
        ExportVariant,
        Pencil,
        Check,
        ChevronDown,
        ChevronUp,
        ChevronLeft,
        Close,
        VueperSlides,
        VueperSlide,
        MapListingPage,
        MobilePopupConfiguration,
        MobilePopupLayout,
        StyleMoreInfo,
        PopupContact,
        MobilePopupMap,
        PopupFindHow,
        NarTooltip,
        ChevronLeft,
        SendOutline,
        PencilOutline,
        BrushVariant
    }
}

</script>