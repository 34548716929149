<template>
  <!-- <section class="lg:w-10/12 w-full mx-auto bg-green p-6 br-10"  v-if="!additionalInfo.wantsAgencyValuation && requestType=='vendi'"> -->
    <section class="lg:w-10/12 w-full mx-auto bg-green p-6 br-10" >
      <h4 class="text-2xl text-white font-bold">Vendilo tramite<br>agenzia convenzionata!</h4>

      <div class="mt-6 bg-white lg:p-6 p-4 br-10">

      <div class="grid lg:grid-cols-4 grid-cols-2 lg:gap-10 lg:gap-y-16 gap-y-4 gap-x-4">
          <div class="vendilo-icon">
          <img class="mx-auto" src="@/assets/img/icons/scontistiche.svg" alt="" />
          <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Scontistiche</p>
          <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Ottieni sconti sulle<br>provvigioni agenzia
          </p>
          </div>

          <div class="vendilo-icon">
          <img class="mx-auto" src="@/assets/img/icons/esperienza.svg" alt="" />
          <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Esperienza</p>
          <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Profonda conoscenza<br>del territorio</p>
          </div>

          <div class="vendilo-icon">
          <img class="mx-auto" src="@/assets/img/icons/valorizzazione.svg" alt="" />
          <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Valorizzazione</p>
          <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Massima valorizzazione<br>del tuo immobile</p>
          </div>

          <div class="vendilo-icon">
          <img class="mx-auto" src="@/assets/img/icons/selezione.svg" alt="" />
          <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Selezione</p>
          <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Selezioniamo le migliori<br>agenzie della zona
          </p>
          </div>
      </div>
      <div v-if="!this.sentRequest">
          <p class="f15-ls05 text-green lh-18 font-bold vendilo-desc">
          Richiedi una valutazione ancora più precisa del tuo<br class="lg:block hidden">
          immobile da un'agenzia del tuo territorio!
          </p>
          <div class="flex justify-center mt-4">
          <button
              class="rounded-full px-5 lg:px-12 p-4 md:p-6 mb-8 bg-green text-white flex items-center mx-auto font-bold "
              type="button" style="min-width: 300px" v-on:click="confirmAgencyInterest()">
              <span class="font-bold mr-3 f10-ls04 md:f20-ls04">INVIA RICHIESTA GRATUITA</span>
              <arrow-right fillColor="#FFFFFF" />
          </button>
          </div>
      </div>
      




      </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
props: {
  additionalInfo: Object,
  requestType: String,
  sentRequest: Boolean,
  firestoreId: String

},
data() {
  return {
    sentRequest: false
  }
  
},
methods: {
  confirmAgencyInterest() {
    // this.setRequestSent(true);
      axios
        .post(
        process.env.VUE_APP_updateAgencyEvaluationStatus,
          {
            firestoreId: this.firestoreId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.setRequestSent(true);
        });
    },
    setRequestSent(status) {
      this.sentRequest = status;
      console.log(status);
      this.$emit('setRequestSent', status);
    },
},
}
</script>