<template>
  <div>


    <section>
      <div class="flex flex-wrap justify-center text-center">
        <div class="
          w-full
          lg:mt-10 lg:rounded-2xl
          bg-white
          shadow-2xl
          pt-10
          pb-16
          npy-20
        ">
          <div class="relative mb-6 px-4 lg:px-0">
            <h2 class="font-bold text-black f30-ls07">Ecco la tua valutazione</h2>

            <section class="grid lg:grid-cols-2 lg:gap-10 lg:w-10/12 mx-auto lg:mt-10 lg:mb-10 mb-5">
              <div class="lg:block hidden">
                <h4 class="landing-subhead font-bold">Dati immobile</h4>
                <div class="grid grid-cols-2 lg:mt-6 mt-2 lg:gap-2 items-end">
                  <div class="border py-4 items-center bg-beige rounded-lg">
                    <img v-if="this.typology == 'Appartamento'" class="mx-auto" src="@/assets/img/apartment-icon.png"
                      alt="" style="width: 50px" />
                    <img v-if="this.typology == 'Casa indipendente'" class="mx-auto"
                      src="@/assets/img/indipendente_icon.png" alt="" style="width: 50px" />
                    <img v-if="this.typology == 'Loft / Open Space'" class="mx-auto" src="@/assets/img/loft_icon.png"
                      alt="" style="width: 50px" />
                    <img v-if="this.typology == 'Rustico / Casale'" class="mx-auto" src="@/assets/img/rustico.png" alt=""
                      style="width: 50px" />
                    <img v-if="this.typology == 'Mansarda'" class="mx-auto" src="@/assets/img/mansarda-icon.png" alt=""
                      style="width: 50px" />
                    <img v-if="this.typology == 'Attico / Mansarda' || this.typology == 'Attico'" class="mx-auto"
                      src="@/assets/img/attico_icon.png" alt="" style="width: 50px" />
                    <img v-if="this.typology == 'Villetta a schiera'" class="mx-auto" src="@/assets/img/villetta_icon.png"
                      alt="" style="width: 50px" />
                    <img v-if="this.typology == 'Palazzo / Stabile'" class="mx-auto" src="@/assets/img/building.png"
                      alt="" style="width: 50px" />

                    <h5 class="text-center font-bold text-black f18-ls004 mt-4">
                      {{ this.typology }}
                    </h5>
                  </div>
                  <div class="border py-4 items-center bg-beige rounded-lg">
                    <img class="mx-auto" src="@/assets/img/sqm.png" alt="" />
                    <h5 class="text-center font-bold text-black f18-ls004 mt-4">
                      {{ this.planimetry.surface }} Mq
                    </h5>
                  </div>
                  <div class="border py-4 items-center bg-beige rounded-lg">
                    <img class="mx-auto" src="@/assets/img/stairs.png" alt="" />
                    <h5 class="text-center font-bold text-black f18-ls004 mt-4">
                      {{ this.planimetry.floor }}° piano
                    </h5>
                  </div>
                  <div class="border py-4 items-center bg-beige rounded-lg">
                    <img class="mx-auto" src="@/assets/img/blueprint.png" alt="" />
                    <h5 class="text-center font-bold text-black f18-ls004 mt-4">
                      {{ this.planimetry.locali }} locali
                    </h5>
                  </div>
                </div>
              </div>

              <div class="lg:mt-0 mt-6 lg:p-0 p-5 lg:shadow-none shadow-lg lg:border-none border-1 br-10">
                <h4 class="landing-subhead font-bold lg:block hidden">Valutazione</h4>
                <div class="grid grid-cols-4 lg:mt-6 lg:gap-2 items-end">
                  <div class="">
                    <p class="font-bold f18-ls04 text-60">
                      {{ formatPrice(this.prezzo_acquisto_previsto - 20000) }}
                    </p>
                  </div>
                  <div class="col-span-2">
                    <h1 class="f35-ls08 font-bold text-green">
                      {{ formatPrice(this.prezzo_acquisto_previsto) }}
                    </h1>
                    <span class="na-label font-bold f12-ls003 text-green">{{ formatPrice(this.prezzo_mq_previsto) }}/Mq
                    </span>
                  </div>
                  <div class="">
                    <p class="font-bold f18-ls04 text-60">
                      {{ formatPrice(this.prezzo_acquisto_previsto + 20000) }}
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-1 lg:mt-2 items-center">
                  <div class="
                    flex
                    w-full
                    inline-flex
                    items-center
                    lg:px-4
                  ">
                    <div>
                      <div class="relative ml-3 bg-beige rounded-full items-center"
                        style="width: 30px; height: 30px; margin-right: -5px"></div>
                    </div>
                    <div class="relative flex-auto bg-beige items-center newarc-esti-line"
                      style="width: 50px; height: 10px"></div>
                    <div>
                      <div class="relative mr-3 bg-beige rounded-full items-center"
                        style="width: 30px; height: 30px; margin-left: -5px"></div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-4 mt-2 gap-2 items-center px-4">
                  <div class="text-left" style="line-height:12px;">
                    <label class="font-bold f14-ls03  text-a8">Prezzo min.</label>
                  </div>
                  <div class="col-span-2">
                    <label class="font-bold text-green f15-ls003">PREZZO STIMATO</label>
                  </div>
                  <div class="text-right" style="line-height:12px;">
                    <label class="font-bold f14-ls03  text-a8">Prezzo max.</label>
                  </div>
                </div>

                <!-- <div class="flex flex-no-wrap justify-between px-8 mt-6">
                <div>
                  <img src="@/assets/img/arrow-down.png" alt="" />
                </div>
                <div class="col-span-3">
                  <h6 class="font-bold f15-ls043">
                    Tempo di vendita stimato in mesi
                  </h6>
                </div>
                <div>
                  <img src="@/assets/img/arrow-down.png" alt="" />
                </div>
              </div>
              <div class="grid grid-cols-1 mt-2 items-center px-10">
                <img
                  src="@/assets/img/range-image.png"
                  class="w-full mx-auto mt-2"
                  alt=""
                />
              </div>
              <div
                class="
                  flex flex-no-wrap
                  mt-2
                  items-center
                  px-10
                  justify-between
                "
              >
                <div v-for="index in 13" :key="index" class="font-fold">
                  <label class="na-label f16-ls11">{{ index - 1 }}</label>
                </div>
              </div> -->

                <div class="flex mt-4">
                  <div class="mx-auto flex items-center">
                    <svg ref="btnRef0" v-on:mouseenter="toggleTooltip(0)" v-on:mouseleave="toggleTooltip(0)"
                      class="h-8 w-8 text-22252B" viewBox="0 0 24 24" fill="#ECECEC" stroke="#D7D7D7" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="16" x2="12" y2="12" />
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                      >Dark tooltip
                    </svg>

                    &nbsp;
                    <p class="font-bold f11-ls54 text-8e">COSA SIGNIFICA?</p>
                  </div>
                </div>
                <div class="flex">
                  <div class="mx-auto flex items-center">
                    <div ref="tooltipRef0" v-bind:class="{
                      hidden: !tooltipShow[0],
                      block: tooltipShow[0],
                    }" class="
                      bg-white
                      border-0 border-solid border-2
                      bg-white
                      rounded-lg
                      shadow-sm
                      mt-3
                      block
                      font-normal
                      leading-normal
                      text-sm
                      max-w-xs
                      text-left
                      no-underline
                      break-words
                      rounded-lg
                    ">
                      <div>
                        <div class="text-black text-justify p-3">
                          ll nostro algoritmo prende in considerazione gli annunci
                          con caratteristiche simili e il più possibile vicino al
                          to immobile. Questo perché il prezzo al mq dipende molto
                          dalla zona dove è localizzato 'immobile oltre che dalle
                          sue caratteristiche. Ricorda però che il prezzo di
                          vendita che leggi negli annunci è la cifra che vorrebbe
                          realizzare il proprietario dell'immobile. In genere, il
                          prezzo finale di compravendita subisce una scontistica a
                          seguito di una trattativa.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- <div class="lg:w-10/12 w-full mx-auto my-10">
            <hr />
          </div> -->
          <div v-if="(requestType == 'vendi' && this.additionalInfo.wantsToSell)">
            <section
              v-if="status == 'Ottimo/Ristrutturato' || status == 'Nuovo / In costruzione'  || !this.currentImmobile.elevator || this.currentImmobile.unitFloor == 'Terra' || this.currentImmobile.unitFloor == 'Seminterrato' || this.currentImmobile.unitFloor == 'Rialzato' ||  this.currentImmobile.zipCode <= 10120 || this.currentImmobile.zipCode >= 10147">
              <house-not-ok v-bind:sentRequest="sentRequest" v-bind:additionalInfo="additionalInfo"
                v-bind:requestType="requestType" v-bind:firestoreId="firestoreId" @setRequestSent="setRequestSent"
                @confirmAgencyInterest="confirmAgencyInterest" />
            </section>
            <!-- Condizione da cambiare con vendiType? -->
            <section 
            v-if=" (status == 'Buono / Abitabile' || status == 'Da ristrutturare')  && this.currentImmobile.elevator && (this.currentImmobile.unitFloor !== 'Terra' || this.currentImmobile.unitFloor !== 'Seminterrato' || this.currentImmobile.unitFloor !== 'Rialzato') && this.currentImmobile.zipCode > 10120 && this.currentImmobile.zipCode < 10147">
              <house-ok v-bind:additionalInfo="additionalInfo" v-bind:requestType="requestType"
                v-bind:firestoreId="firestoreId" @setRequestSent="setRequestSent"
                @confirmAgencyInterest="confirmAgencyInterest" />
            </section>
            </div>

            <!-- <section v-if="requestType == 'valuta' || requestType == 'curiosity'"> -->
            <section v-if="requestType == 'valuta'">
              <valuation-only v-bind:additionalInfo="additionalInfo" v-bind:requestType="requestType"
                @confirmAgencyInterest="confirmAgencyInterest" />
            </section>



            <!-- Da ristrutturare
          Buono / Abitabile
          Ottimo/Ristrutturato
          Nuovo / In costruzione -->



            <!-- <section class="lg:w-10/12 w-full mx-auto bg-green p-6 br-10"  v-if="!additionalInfo.wantsAgencyValuation && requestType=='vendi'">
            <h4 class="text-2xl text-white font-bold">Vendilo tramite<br>agenzia convenzionata!</h4>

            <div class="mt-6 bg-white lg:p-6 p-4 br-10">

              <div class="grid lg:grid-cols-4 grid-cols-2 lg:gap-10 lg:gap-y-16 gap-y-4 gap-x-4">
                <div class="vendilo-icon">
                  <img class="mx-auto" src="@/assets/img/icons/scontistiche.svg" alt="" />
                  <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Scontistiche</p>
                  <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Ottieni sconti sulle<br>provvigioni agenzia
                  </p>
                </div>

                <div class="vendilo-icon">
                  <img class="mx-auto" src="@/assets/img/icons/esperienza.svg" alt="" />
                  <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Esperienza</p>
                  <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Profonda conoscenza<br>del territorio</p>
                </div>

                <div class="vendilo-icon">
                  <img class="mx-auto" src="@/assets/img/icons/valorizzazione.svg" alt="" />
                  <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Valorizzazione</p>
                  <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Massima valorizzazione<br>del tuo immobile</p>
                </div>

                <div class="vendilo-icon">
                  <img class="mx-auto" src="@/assets/img/icons/selezione.svg" alt="" />
                  <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Selezione</p>
                  <p class="vendilo-icon-desc f14-ls03 text-5b font-bold">Selezioniamo le migliori<br>agenzie della zona
                  </p>
                </div>
              </div>
              <div v-if="!this.sentRequest">
                <p class="f15-ls05 text-green lh-18 font-bold vendilo-desc">
                  Richiedi una valutazione ancora più precisa del tuo<br class="lg:block hidden">
                  immobile da un'agenzia del tuo territorio!
                </p>
                <div class="flex justify-center mt-4">
                  <button
                    class="rounded-full px-5 lg:px-12 p-4 md:p-6 mb-8 bg-green text-white flex items-center mx-auto font-bold "
                    type="button" style="min-width: 300px" v-on:click="confirmAgencyInterest()">
                    <span class="font-bold mr-3 f10-ls04 md:f20-ls04">INVIA RICHIESTA GRATUITA</span>
                    <arrow-right fillColor="#FFFFFF" />
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="lg:w-4/12 mx-auto mt-10">
                  <img class="mx-auto" src="@/assets/img/sent_request.png" alt="" style="width: 30px" />
                </div>
                <div class="grid justify-items-center lg:w-12/12 mx-auto mt-6">
                  <p class="text-green font-bold f14-ls015">
                    RICHIESTA INVIATA
                  </p>
                </div>
                <div class="lg:w-12/12 mx-auto mb-16">
                  <p class="text-green font-bold f14-ls015">
                    Verrai ricontattato al più presto!
                  </p>
                </div>
              </div>




            </div>
          </section> -->

            <div class="lg:w-10/12 w-full mx-auto my-10">
              <hr />
            </div>

            <section class="lg:w-10/12 w-full mx-auto">
              <h4 class="f25-ls06 text-black font-bold">Analisi della zona</h4>

              <div class="grid lg:grid-cols-3 grid-cols-2 gap-3 lg:gap-10 lg:gap-y-16 mt-6 analisis-grids">
                <div class="lg:mt-0 lg:border-0 rounded-lg lg:p-0 p-3">
                  <img v-if="this.analisi_zona.attrattivita == '1'" src="@/assets/img/1s.png" class="mx-auto"
                    alt="valutazione una stella" style="width: 180px; margin-top: 18px" />
                  <img v-if="this.analisi_zona.attrattivita == '1.5'" src="@/assets/img/1-5s.png" class="mx-auto"
                    alt="valutazione una stella" style="width: 180px; margin-top: 18px" />
                  <img v-else-if="this.analisi_zona.attrattivita == '2'" src="@/assets/img/2s.png" class="mx-auto"
                    alt="valutazione due stelle" style="width: 180px; margin-top: 18px" />
                  <img v-if="this.analisi_zona.attrattivita == '2.5'" src="@/assets/img/2-5s.png" class="mx-auto"
                    alt="valutazione una stella" style="width: 180px; margin-top: 18px" />
                  <img v-else-if="this.analisi_zona.attrattivita == '3'" src="@/assets/img/3s.png" class="mx-auto"
                    alt="valutazione tre stelle" style="width: 180px; margin-top: 18px" />
                  <img v-if="this.analisi_zona.attrattivita == '3.5'" src="@/assets/img/3-5s.png" class="mx-auto"
                    alt="valutazione una stella" style="width: 180px; margin-top: 18px" />
                  <img v-else-if="this.analisi_zona.attrattivita == '4'" src="@/assets/img/4s.png" class="mx-auto"
                    alt="valutazione quattro stelle" style="width: 180px; margin-top: 18px" />
                  <img v-if="this.analisi_zona.attrattivita == '4.5'" src="@/assets/img/4-5s.png" class="mx-auto"
                    alt="valutazione quattro stelle e mezzo" style="width: 180px; margin-top: 18px" />
                  <img v-if="this.analisi_zona.attrattivita == '5'" src="@/assets/img/5s.png" class="mx-auto"
                    alt="valutazione cinque stelle" style="width: 180px; margin-top: 18px" />
                  <h5 class="font-bold lg:mt-6 mt-2 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Attrattività della zona
                  </h5>
                </div>

                <div class="lg:mt-0 lg:border-0 rounded-lg lg:p-0 p-3">
                  <p class="f36-ls08 font-bold text-green">
                    {{ formatPrice(this.analisi_zona.prezzo_medio_mq) }}
                  </p>
                  <h5 class="font-bold lg:mt-4 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Prezzo medio al mq
                  </h5>
                </div>

                <div class="lg:mt-0 lg:border-0 rounded-lg lg:p-0 p-3">
                  <p class="f36-ls08 font-bold text-green">
                    {{ this.analisi_zona.tempo_medio_vendita }}
                  </p>
                  <h5 class="font-bold lg:mt-4 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Tempo di vendita medio
                  </h5>
                </div>

                <div class="lg:mt-0 lg:border-0 rounded-lg lg:p-0 p-3">
                  <p class="f36-ls08 font-bold text-green">
                    {{ this.analisi_zona.supermercati }}
                  </p>
                  <h5 class="font-bold lg:mt-4 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Supermercati
                  </h5>
                </div>

                <div class="lg:mt-0  lg:border-0 rounded-lg lg:p-0 p-3">
                  <p class="f36-ls08 font-bold text-green">
                    {{ this.analisi_zona.scuole }}
                  </p>
                  <h5 class="font-bold lg:mt-4 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Scuole
                  </h5>
                </div>
                <div class="lg:mt-0 lg:border-0 rounded-lg lg:p-0 p-3">
                  <p class="f36-ls08 font-bold text-green">
                    {{ this.analisi_zona.parchi }}
                  </p>
                  <h5 class="font-bold lg:mt-4 lg:w-5/12 mx-auto f18-ls04 text-68">
                    Parchi
                  </h5>
                </div>
              </div>
            </section>

            <div class="lg:w-10/12 w-full mx-auto my-10">
              <hr />
            </div>

            <section class="lg:w-10/12 w-full mx-auto">
              <h4 class="f25-ls06 font-bold text-black">
                Caratteristiche della zona
              </h4>

              <div class="w-full relative">
                <div class="lg:absolute right-5 top-0 lg:mt-0 mt-3">
                  <div class="mx-auto flex justify-center items-center">

                    <svg ref="btnRef1" v-on:mouseenter="toggleTooltip(1)" v-on:mouseleave="toggleTooltip(1)"
                      class="h-8 w-8 text-22252B" viewBox="0 0 24 24" fill="#ECECEC" stroke="#D7D7D7" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="16" x2="12" y2="12" />
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                      >Dark tooltip
                    </svg>
                    <div ref="tooltipRef1" v-bind:class="{
                      hidden: !tooltipShow[1],
                      block: tooltipShow[1],
                    }"
                      class=" w-80 bg-white border-0 border-solid border-2 bg-white rounded-lg shadow-sm mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg ">
                      <div>
                        <div class="text-black text-justify p-3">
                          Questo indice valuta la qualità del tuo immmobile nel
                          contesto in cui si trova, in base alle caratteristiche
                          più ricercate dagli acquirenti potenziali.
                          <br /><br />Ad esempio, se il tuo immobile ha un
                          terrazzo, caratteristica rara e molto ricercata nella
                          tua zona, il valore sarà più alto.
                        </div>
                      </div>
                    </div>
                    &nbsp;
                    <p class="font-bold text-8e text-left f11-ls54 ml-1">
                      CHE COS'È<br />QUESTO INDICE?
                    </p>
                  </div>
                </div>

                <div class="flex items-center justify-center mt-6 " style="margin-left: 40px; margin-right: 40px">
                  <img src="@/assets/img/home-arrow-icon.png" alt="" :style="btnStyles" />
                </div>

                <div style="margin-left: 20px; margin-right: 20px" class="flex items-center justify-center">
                  <img src="@/assets/img/red_house.png" alt="" srcset="" class="mr-4" />
                  <img src="@/assets/img/range-character.png" alt="" srcset="" class="lg:w-auto w-10/12" />
                  <img src="@/assets/img/green_house.png" alt="" srcset="" class="ml-4" />
                </div>
              </div>

              <div class="flex items-center justify-center mt-2">
                <p class="na-label font-bold f15-ls05 text-999">
                  Indice delle caratteristiche del tuo immobile
                </p>
              </div>

              <div class="grid lg:grid-cols-2 lg:gap-14 gap-4 mt-6">
                <div class="border bg-beige p-5 pb-8 rounded-xl">
                  <h3 class="text-left f25-ls06 font-bold mb-4">Pro</h3>
                  <div v-for="pro in this.analisi_caratteristiche.pro" v-bind:key="pro"
                    class="flex justify-start items-center mt-2">
                    <img class="mr-5" src="@/assets/img/green_check.png" alt="" srcset="" />
                    <h5 class="font-bold text-left f20-ls04 text-48">
                      {{ pro }}
                    </h5>
                  </div>
                </div>

                <div class="border bg-beige p-5 pb-8 rounded-xl">
                  <h3 class="text-left f25-ls06 font-bold mb-4">Contro</h3>
                  <div v-for="contro in this.analisi_caratteristiche.contro" v-bind:key="contro"
                    class="flex justify-start items-center mt-2">
                    <img class="mr-5" src="@/assets/img/red_cross.png" alt="" srcset="" />
                    <h5 class="font-bold text-left f20-ls04 text-48">
                      {{ contro }}
                    </h5>
                  </div>
                  <!--<div class="flex justify-start items-center mt-2">
                  <img
                    class="mr-5"
                    src="@/assets/img/red_cross.png"
                    alt=""
                    srcset=""
                  />
                  <h5 class="font-bold text-left f20-ls04 text-48">
                    Spese condominiali
                  </h5>
                </div>
                <div class="flex justify-start items-center mt-2">
                  <img
                    class="mr-5"
                    src="@/assets/img/red_cross.png"
                    alt=""
                    srcset=""
                  />
                  <h5 class="font-bold text-left f20-ls04 text-48">
                    Assenza di cantina
                  </h5>
                </div>-->
                </div>
              </div>
            </section>

            <div class="lg:w-10/12 w-full mx-auto lg:my-10 my-5">
              <hr />
            </div>

            <section class="lg:w-10/12 w-full mx-auto">
              <div class="relative items-center mb-6">
                <h4 class="text-black f25-ls06 font-bold">
                  Annunci simili vicino al tuo immobile
                </h4>
                <div class="lg:absolute right-5 top-0 lg:mt-0 mt-3">
                  <div class="mx-auto flex items-center justify-center">

                    <svg ref="btnRef2" v-on:mouseenter="toggleTooltip(2)" v-on:mouseleave="toggleTooltip(2)"
                      class="h-8 w-8 text-22252B" viewBox="0 0 24 24" fill="#ECECEC" stroke="#D7D7D7" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="16" x2="12" y2="12" />
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                      >Dark tooltip
                    </svg>
                    <div ref="tooltipRef2" v-bind:class="{
                      hidden: !tooltipShow[2],
                      block: tooltipShow[2],
                    }"
                      class=" w-80 bg-white border-0 border-solid border-2 bg-white rounded-lg shadow-sm mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg ">
                      <div>
                        <div class="text-black text-justify p-3">
                          Il nostro algoritmo prende in considerazione gli annunci
                          con caratteristiche simili e il più possibile vicino al
                          tuo immobile. Questo perché il prezzo al mq dipende
                          molto dalla zona dove è localizzato l'immobile oltre che
                          dalle sue caratteristiche. Ricorda però che il prezzo di
                          vendita che leggi negli annunci è la cifra che vorrebbe
                          realizzare il proprietario dell'immobile. In genere, il
                          prezzo finale di compravendita subisce una scontistica a
                          seguito di una trattativa.
                        </div>
                      </div>
                    </div>
                    &nbsp;
                    <p class="font-bold text-8e text-left f11-ls54 ml-1">
                      COME FUNZIONA<br />L'ALGORITMO?
                    </p>
                  </div>
                </div>
              </div>

              <div class="overflow-hidden lg:mt-0" style="height: 260px">
                <map-example v-model="this.currentImmobile" v-model:additionalMarkers="this.annunci_simili" />
              </div>

              <div class="mt-10 grid lg:grid-cols-2 lg:gap-14 gap-4" v-if="this.annunci_simili[0]">
                <!-- <div class="flex" v-if="this.annunci_simili[0]"> -->
                <div class="flex">
                  <div class="w-5/12 relative">
                    <img src="@/assets/img/dummy-property-image.png" class="absolute top-0 left-0 z-0 h-full w-full"
                      alt="" />
                    <div class="
                      absolute
                      top-2
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f11-ls007 text-666 font-bold">MQ
                        <strong>{{ this.annunci_simili[0].mq }}</strong></label>
                    </div>
                    <div class="
                      absolute
                      bottom-4
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f12-ls008 font-bold">{{
                        this.annunci_simili[0].address
                      }}</label>
                    </div>
                  </div>
                  <div class="w-7/12 p-4 bg-beige rounded-r-lg border">
                    <h6 class="text-left font-bold f14-ls09 text-black">
                      {{ this.annunci_simili[0].tipo }}
                    </h6>
                    <p class="flex justify-between mt-4">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Prezzo</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[0].prezzo)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">€/Mq</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[0].prezzo_mq)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Locali</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[0].locali
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Somiglianza</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[0].somiglianza
                      }}</strong>
                    </p>
                  </div>
                </div>

                <div class="flex" v-if="this.annunci_simili[1]">
                  <div class="w-5/12 relative">
                    <img src="@/assets/img/dummy-property-image.png" class="absolute top-0 left-0 z-0 h-full w-full"
                      alt="" />
                    <div class="
                      absolute
                      top-2
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f11-ls007 text-666 font-bold">MQ
                        <strong>{{ this.annunci_simili[1].mq }}</strong></label>
                    </div>
                    <div class="
                      absolute
                      bottom-4
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f12-ls008 font-bold">{{
                        this.annunci_simili[1].address
                      }}</label>
                    </div>
                  </div>
                  <div class="w-7/12 p-4 bg-beige rounded-r-lg border">
                    <h6 class="text-left font-bold f14-ls09 text-black">
                      {{ this.annunci_simili[1].tipo }}
                    </h6>
                    <p class="flex justify-between mt-4">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Prezzo</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[1].prezzo)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">€/Mq</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[1].prezzo_mq)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Locali</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[1].locali
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Somiglianza</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[1].somiglianza
                      }}</strong>
                    </p>
                  </div>
                </div>

                <div class="flex" v-if="this.annunci_simili[2]">
                  <div class="w-5/12 relative">
                    <img src="@/assets/img/dummy-property-image.png" class="absolute top-0 left-0 z-0 h-full w-full"
                      alt="" />
                    <div class="
                      absolute
                      top-2
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f11-ls007 text-666 font-bold">MQ
                        <strong>{{ this.annunci_simili[2].mq }}</strong></label>
                    </div>
                    <div class="
                      absolute
                      bottom-4
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f12-ls008 font-bold">{{
                        this.annunci_simili[2].address
                      }}</label>
                    </div>
                  </div>
                  <div class="w-7/12 p-4 bg-beige rounded-r-lg border">
                    <h6 class="text-left font-bold f14-ls09 text-black">
                      {{ this.annunci_simili[2].tipo }}
                    </h6>
                    <p class="flex justify-between mt-4">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Prezzo</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[2].prezzo)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">€/Mq</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[2].prezzo_mq)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Locali</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[2].locali
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Somiglianza</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[2].somiglianza
                      }}</strong>
                    </p>
                  </div>
                </div>

                <div class="flex" v-if="this.annunci_simili[3]">
                  <div class="w-5/12 relative">
                    <img src="@/assets/img/dummy-property-image.png" class="absolute top-0 left-0 z-0 h-full w-full"
                      alt="" />
                    <div class="
                      absolute
                      top-2
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f11-ls007 text-666 font-bold">MQ
                        <strong>{{ this.annunci_simili[3].mq }}</strong></label>
                    </div>
                    <div class="
                      absolute
                      bottom-4
                      left-2
                      z-10
                      bg-beige
                      px-4
                      py-1
                      rounded-lg
                    ">
                      <label class="f12-ls008 font-bold">{{
                        this.annunci_simili[3].address
                      }}</label>
                    </div>
                  </div>
                  <div class="w-7/12 p-4 bg-beige rounded-r-lg border">
                    <h6 class="text-left font-bold f14-ls09 text-black">
                      {{ this.annunci_simili[3].tipo }}
                    </h6>
                    <p class="flex justify-between mt-4">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Prezzo</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[3].prezzo)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">€/Mq</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        formatPrice(this.annunci_simili[3].prezzo_mq)
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Locali</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[3].locali
                      }}</strong>
                    </p>
                    <p class="flex justify-between mt-1">
                      <label for="" class="text-left font-bold f12-ls008 text-80">Somiglianza</label>
                      <strong class="text-right f12-ls009 text-black">{{
                        this.annunci_simili[3].somiglianza
                      }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div
          v-if="!additionalInfo.wantsAgencyValuation && requestType == 'vendi' && !((status == 'Buono / Abitabile' || status == 'Da ristrutturare') && this.currentImmobile.elevator && (this.currentImmobile.unitFloor !== 'Terra' || this.currentImmobile.unitFloor !== 'Seminterrato' || this.currentImmobile.unitFloor !== 'Rialzato') && (this.currentImmobile.zipCode > 10120 && this.currentImmobile.zipCode < 10147))"
          class="mt-32 bg-green">
          <div class="w-full md:w-screen overflow-hidden bg-beige"></div>
          <div class="flex justify-center px-5 -mt-12">
            <img class="h-32 w-32 bg-green p-2 rounded-full bg-contain" src="@/assets/img/agente2.jpg" />
          </div>
          <div class="text-center px-10 pt-4 md:mb:0 mb-6">
            <h2 class="text-white text-2xl font-bold">
              Vendilo tramite agenzia convenzionata!
            </h2>
          </div>
          <div class="
            bg-white
            rounded-lg
            pt-1
            md:pt-4 md:pt-6 md:mx-24
            mx-4
            md:my-
            mb-8
            bg-gray-100
            shadow-xl
          ">
            <div class="
              grid
              grid-cols-2
              lg:grid-cols-4
              sm:col-start-1
              col-end-7
              my-8
              px-6
              md:ml-0
            ">
              <div class="h-48 w-auto grid justify-items-center">
                <img class="h-20 w-20 mx-14 mb-3 value-img" src="@/assets/img/Group1.png" />
                <h2 class="text-black f18-ls004 font-bold value-tt">Scontistiche</h2>
                <h2 class="text-black f14-ls03 value-txt">
                  Ottieni sconti sulle<br />provvigioni agenzia
                </h2>
              </div>
              <div class="h-48 w-auto grid justify-items-center">
                <img class="h-20 w-20 mb-3 value-img" src="@/assets/img/Group1-2.png" />
                <h2 class="text-black f18-ls004 font-bold value-tt">Esperienza</h2>
                <h2 class="text-black f14-ls03 value-txt">
                  Profonda conoscenza<br />del territorio
                </h2>
              </div>
              <div class="h-48 w-auto grid justify-items-center">
                <img class="h-20 w-20 mx-14 mb-3 value-img" src="@/assets/img/Group1-3.png" />
                <h2 class="text-black f18-ls004 font-bold value-tt">Valorizzazione</h2>
                <h2 class="text-black f14-ls03 value-txt">
                  Massima valorizzazione<br />del tuo immobile
                </h2>
              </div>
              <div class="h-48 w-auto grid justify-items-center">
                <img class="h-20 w-20 mx-14 mb-3 value-img" src="@/assets/img/Group1-4.png" />
                <h2 class="text-black f18-ls004 font-bold value-tt">Selezione</h2>
                <h2 class="text-black f14-ls03 value-txt">
                  Selezioniamo le migliori<br />agenzie della zona
                </h2>
              </div>
            </div>
            <div class="grid justify-items-center">
              <div v-if="!this.sentRequest">
                <h2 class="text-green f15-ls05 md:text-lg pb-6 md:py-6 px-6">
                  Richiedi una valutazione ancora più precisa del tuo immobile da
                  un'agenzia del tuo territorio!
                </h2>
                <button
                  class="rounded-full px-5 lg:px-12 p-4 md:p-6 mb-8 bg-green text-white flex items-center mx-auto font-bold "
                  type="button" style="min-width: 300px" v-on:click="confirmAgencyInterest()">
                  <span class="font-bold mr-3 f10-ls04 md:f20-ls04">INVIA RICHIESTA GRATUITA</span>
                  <arrow-right fillColor="#FFFFFF" />
                </button>
              </div>
              <div v-else>
                <div class="lg:w-4/12 mx-auto mt-10">
                  <img class="mx-auto" src="@/assets/img/sent_request.png" alt="" style="width: 30px" />
                </div>
                <div class="grid justify-items-center lg:w-12/12 mx-auto mt-6">
                  <p class="text-green font-bold f14-ls015">
                    RICHIESTA INVIATA
                  </p>
                </div>
                <div class="lg:w-12/12 mx-auto mb-16">
                  <p class="text-green font-bold f14-ls015">
                    Verrai ricontattato al più presto!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mb-16"></div>
      </div>
    </section>

    <div v-if="sentRequest == true"
      class="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center z-100 value-info-sent-popup">
      <div class="flex flex-col justify-center items-center value-info-sent-popup-container relative">
        <div class="absolute right-5 top-5 cursor-pointer" @click="setRequestSent(false)">
          <close fillColor="#000000" />
        </div>
        <div class="lg:w-4/12 mx-auto">
          <img class="mx-auto" src="@/assets/img/sent_request.png" alt="" style="width: 43px" />
        </div>
        <div class="grid justify-items-center lg:w-12/12 mx-auto mt-6">
          <p class="text-green font-bold f14-ls015 value-info-sent-popup-heading">
            RICHIESTA INVIATA
          </p>
        </div>
        <div class="lg:w-12/12 mx-auto">
          <p class="text-green font-bold f14-ls015 value-info-sent-popup-text">
            Verrai ricontattato al più presto!
          </p>
        </div>
      </div>

    </div>
    <footer-component class="pt-6" />
  </div>
</template>
<script>
import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapExample from "@/components/Maps/MapExample.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { createPopper } from "@popperjs/core";
import axios from "axios";

import ValuationOnly from "@/components/ConfiguratorSteps/valuation-box/ValuationOnly.vue";
import HouseOk from "@/components/ConfiguratorSteps/valuation-box/HouseOk.vue";
import HouseNotOk from "@/components/ConfiguratorSteps/valuation-box/HouseNotOk.vue";
import Close from "vue-material-design-icons/Close.vue";

export default {
  props: {
    currentImmobile: Object,
    valuationResult: Object,
    planimetry: Object,
    typology: String,
    additionalInfo: Object,
    requestType: String,
    valutaType: String,
    status: String
  },
  components: {
    InformationVariant,
    MapExample,
    ArrowRight,
    FooterComponent,
    ValuationOnly,
    HouseOk,
    HouseNotOk,
    Close
  },
  mounted(){
    console.log("LOGGHISSIMO")
    console.log(this.currentImmobile);
  },
  computed: {
    btnStyles() {
      //let marginLeft = (this.analisi_caratteristiche.indice - 50) * 1.6
      let proLength = 0;
      let controLength = 0;
      if (this.analisi_caratteristiche.pro != undefined) {
        proLength = this.analisi_caratteristiche.pro.length
      }
      if (this.analisi_caratteristiche.contro != undefined) {
        controLength = this.analisi_caratteristiche.contro.length
      }

      let innerWidth = window.innerWidth
      let tickLength = 0
      if (innerWidth > 1000) {
        innerWidth = 1000
        tickLength = innerWidth * 10 / (12 * 16 * 4)
      } else {
        tickLength = innerWidth * 10 / (12 * 16)
      }



      let marginLeft = (proLength - controLength) * tickLength
      console.log(marginLeft)
      return {
        width: "36px",
        "margin-left": `${marginLeft}%`,
      };
    },
  },
  data() {
    console.log(this.valuationResult);

    return {
      tooltipShow: [false, false, false],
      sentRequest: false,
      //valuationResult: this.valuationResult,
      agency: null,
      firestoreId: this.valuationResult.firestore_id,
      prezzo_acquisto_previsto: this.valuationResult.prezzo_acquisto_previsto,
      prezzo_mq_previsto: this.valuationResult.prezzo_mq_previsto,
      analisi_caratteristiche: this.valuationResult.analisi_caratteristiche,
      analisi_zona: this.valuationResult.analisi_zona,
      annunci_simili: this.valuationResult.annunci_simili,
    };
  },
  methods: {
    toggleTooltip(i) {
      let ref;
      let tooltipRef;
      if (i == 0) {
        ref = this.$refs.btnRef0;
        tooltipRef = this.$refs.tooltipRef0;
      } else if (i == 1) {
        ref = this.$refs.btnRef1;
        tooltipRef = this.$refs.tooltipRef1;
      } else if (i == 2) {
        ref = this.$refs.btnRef2;
        tooltipRef = this.$refs.tooltipRef2;
      }

      if (this.tooltipShow[i]) {
        this.tooltipShow[i] = false;
      } else {
        this.tooltipShow[i] = true;
        createPopper(ref, tooltipRef, {
          placement: "bottom",
        });
      }
    },
    setRequestSent(status) {
      console.log(status);
      this.sentRequest = status;
    },
    confirmAgencyInterest() {
      axios
        .post(
          process.env.VUE_APP_updateAgencyEvaluationStatus,
          {
            firestoreId: this.firestoreId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.sentRequest = true;
        });
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 0,
      });

      return formatter.format(value);
    },
    selectAgency(agencyNumber) {
      this.agency = agencyNumber;
    },
  },
};
</script>
