<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
            showContactPop == true ? 'block' : 'hidden'
        ]" >
            
        <div class="relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center br-10">

            <div class="lg:w-11/12 w-full nap-popup-container flex flex-row items-center justify-center bg-white br-10">
                <div class="h-full w-full overflow-hidden relative flex flex-row br-10">
                    <div class="nap-contact-form-close-button absolute right-2 top-2 cursor-pointer z-50 flex flex-row items-center justify-center" @click="hideContactPopup()">
                        <close fillColor="#919191" />
                    </div>

                    <div v-if="!isSent" class="lg:p-10 lg:w-1/2 w-full h-full">
                    <form action="https://formcarry.com/s/vhbU7NVQ_W" method="POST" accept-charset="UTF-8">
                        <h3 class="text-green font-bold f18-ls79 lg:block hidden">La tua nuova casa</h3>

                        <div class="flex flex-col justify-between lg:pl-10 h-full lg:h-auto">

                            <div class="flex flex-col w-full">
                                
                                <h3 class="nap-contact-form-mobile-header text-green font-bold f18-ls79 lg:hidden block flex flex-row items-center w-full justify-center">La tua nuova casa</h3>

                                <div class="lg:p-0 p-5">
                                    <div class="flex flex-col nap-input-container">
                                        <label class="f14-ls09 lg:text-left text-center">Con chi parleremo?</label>
                                        <input type="text" class="f12-ls011" placeholder="Nome e Cognome" name="fullName" required>
                                    </div>

                                    <div class="flex flex-col nap-input-container">
                                        <label class="f14-ls09 lg:text-left text-center">Dove ti contattiamo?</label>
                                        <input type="text" placeholder="Telefono" name="phoneNumber" required>
                                        <input type="text" placeholder="E-mail" name="emailAddress" required>
                                    </div>

                                    <div class="flex flex-col nap-input-container">
                                        <label class="f14-ls09 lg:text-left text-center">Scegli un orario</label>
                                        <input type="text" placeholder="A che ora passiamo contattarti?" required>
                                        
                                    </div>
                                </div>

                                
                            </div>
                            
                            <div class="flex flex-col w-full lg:p-0 p-5">
                                <div class="flex flex-row items-start nap-input-container">
                                    <input type="checkbox" required>
                                    <p>Ho letto la Privacy Policy e acconsento al trattamento dei miei dati per essere ricontattato da Newarc Srl.</p>
                                    <input type="hidden" name="_gotcha">
                                </div>

                                <div class="flex flex-row items-start nap-input-container">
                                    <button  class="bg-green text-white font-bold w-full p-3 br-10">Invia richiesta</button>
                                </div>
                            </div>

                        </div>
                    </form>

                    </div>
                    <div v-else class="lg:p-10 lg:w-1/2 w-full h-full items-center flex flex-col justify-start">
                        <h3 class="nap-contact-form-mobile-header text-green font-bold f18-ls79 lg:hidden block flex flex-row items-center w-full justify-center">La tua nuova casa</h3>
                        <div class="flex flex-row justify-center items-center w-full h-1/3">
                            <img src="@/assets/img/sent_request.png" class="mb-10">
                        </div>
                        <div class="flex flex-col item-center justify-start w-full  h-2/3">
                            <h2 class="text-green font-bold mb-5 text-center">Grazie!</h2>
                            <h5 class="text-black font-bold text-center">
                                La tua richiesta <br />è stata inviata correttamente. <br />Ti contatteremo nella fascia oraria<br /> che hai indicato.
                            </h5>
                        </div>
                        
                        

                        

                    </div>
                    <div class="w-1/2 h-full relative lg:block hidden">
                        <div class="nap-contact-form-image-container w-full h-full absolute top-0 left-0"></div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default{
    props: {
        showContactPop: {
            type: Boolean,
            required: true
        }
    },
    emits: ["hideContactPopup"],
    methods: {
        
        hideContactPopup(){
            this.$emit("hideContactPopup");
        },
        fakeSend(){
            this.isSent = true;
        }
        
    },
    data() {
        return {
            isSent: false
        }
    },
    components: {
        Close
    }
}

</script>