<template>
  <section>
    <div class="">
      <div class="flex flex-wrap justify-center text-left">
        <div class="lg:w-10/12 lg:px-10 px-5 py-6 pb-16">
          <div class="flex flex-wrap">
            <img class="mx-auto" src="@/assets/img/facultative-btn.png" />
            <button type="button"
                  v-on:click='skipStep()'><p style="color: #489B79;text-decoration: underline;">Salta</p></button>
          </div>
          <h2 class="f30-ls07 font-bold text-black text-center">
            Vuoi una valutazione più precisa?
          </h2>

          <div class="grid lg:grid-cols-2 lg:gap-20 gap-4 mt-10">
            <!-- Column 1 -->
            <div>
              <h5 class="text-center text-black font-bold mb-2 f18-ls56">
                Carica delle foto del tuo immobile
              </h5>

              <div class="bg-white border-2 border-dashed p-6 rounded-xl">
                <div v-if="houseImages.images.length == 0">
                  <img
                    src="@/assets/img/image-placeholder.png"
                    class="mx-auto"
                    alt=""
                  />
                  <h6 class="lg:w-5/12 text-center mx-auto mt-4 mb-12">
                    Trascina qui le foto o clicca su carica.
                  </h6>
                </div>

                <div
                  v-else-if="houseImages.images.length > 0"
                  class="grid grid-cols-4 gap-4 grid-rows-2"
                >
                  <div
                    v-for="(item, index) in houseImages.images"
                    v-bind:key="item.id"
                    class="relative"
                  >
                    <img class="w-16 h-16 rounded-md mx-auto" :src="item" />
                    <close-circle-outline
                      v-on:click="removeHouseImage(index)"
                      fillColor="#888"
                      class="absolute top-0 right-4 bg-white rounded-xl"
                    />
                  </div>
                </div>
                <!-- <img src="@/assets/img/image-placeholder.png" class="mx-auto" alt="">
                <h6 class="lg:w-5/12 text-center mx-auto mt-4 mb-10">Trascina qui le foto o clicca su carica. Styles</h6> -->

                <div class="grid grid-cols-1 text-center mt-4">
                  <label
                    for="file-input"
                    class="
                      text-white
                      f18-ls04
                      font-bold
                      text-center
                      w-min
                      mx-auto
                    "
                  >
                    <div class="load-button flex items-center justify-center">
                      Carica
                      <img
                        style="height: 24px; width: 24px"
                        class="ml-4"
                        src="@/assets/img/upload-image-icon.png"
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    @change="addHouseImage($event)"
                    id="file-input"
                  />
                </div>
              </div>
            </div>

            <!-- Column 2 -->
            <div>
              <h5 class="text-center text-black font-bold mb-2 f18-ls56">
                Carica la piantina
              </h5>

              <div class="bg-white border-2 border-dashed p-6 rounded-xl">
                <div v-if="!this.houseImages.planimetryFile">
                  <img
                    src="@/assets/img/blueprint.png"
                    class="mx-auto"
                    alt=""
                  />
                  <h6
                    class="
                      lg:w-5/12
                      text-center
                      mx-auto
                      mt-4
                      mb-12
                      f16-ls02
                      text-6a
                    "
                  >
                    Trascina qui le foto o clicca su carica.
                  </h6>
                </div>

                <div v-else class="grid grid-cols-4 gap-4 grid-rows-2">
                  <div class="relative">
                    <img
                      class="w-16 h-16 rounded-md mx-auto"
                      :src="this.houseImages.planimetryImage"
                    />
                    <close-circle-outline
                      v-on:click="removeHousePlanimetry()"
                      fillColor="#888"
                      class="absolute top-0 right-4 bg-white rounded-xl"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-1 text-center">
                  <label
                    for="planimetry-input"
                    class="
                      text-white
                      f18-ls04
                      font-bold
                      text-center
                      w-min
                      mx-auto
                    "
                  >
                    <div class="load-button flex items-center justify-center">
                      Carica
                      <img
                        style="height: 24px; width: 24px"
                        class="ml-4"
                        src="@/assets/img/upload-image-icon.png"
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    @change="addHousePlanimetry($event)"
                    id="planimetry-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CloseCircleOutline from "vue-material-design-icons/CloseCircleOutline.vue";
export default {
  props: {
    modelValue: Object,
  },
  components: {
    CloseCircleOutline,
  },
  data() {
    return {
      houseImages: this.modelValue,
      placeholderImage:
        "https://images.unsplash.com/photo-1641367392721-a94e13e75e4d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80I",
    };
  },
  emits: ['editHouseImages', 'increaseStep'],
  methods: {
    addHouseImage(event) {
      console.log("here");
      if (this.houseImages.images == 6) {
        alert("Hai raggiunto il numero massimo di foto caricabili");
        return;
      }
      console.log("here 2");
      this.houseImages.images.push(
        window.URL.createObjectURL(event.target.files[0])
      );
      this.houseImages.imageFiles.push(event.target.files[0]);
      this.$emit("editHouseImages", this.houseImages);
    },
    removeHouseImage(index) {
      // var index = this.houseImages.images.findIndex(item);
      console.log(index);

      this.houseImages.images.splice(index, 1);
      this.houseImages.imageFiles.splice(index, 1);
      this.$emit("editHouseImages", this.houseImages);
    },
    addHousePlanimetry(event) {
      this.houseImages.planimetryName = event.target.files[0].name;
      this.houseImages.planimetryImage = window.URL.createObjectURL(
        event.target.files[0]
      );
      this.houseImages.planimetryFile = event.target.files[0];
      this.$emit("editHouseImages", this.houseImages);
    },
    removeHousePlanimetry() {
      this.houseImages.planimetryName = null;
      this.houseImages.planimetryFile = null;
      this.$emit("editHouseImages", this.houseImages);
    },
    skipStep(){
        
             this.$emit("increaseStep");
         
           
       },
  },
};
</script>