<template>
  <div>
  <footer v-bind:class=" routeName== 'valuation' ? 'bg-beige' : 'bg-white' " class="relative lg:pt-20 lg:pb-10 px-5 pt-8">
    <div
      class=" bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden "
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-gray-300 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto lg:px-4">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/3 lg:px-4">
          <img class="mx-auto lg:mx-0 mb-6 sm:px-0"  style="object-fit: cover; max-width:112px;"  src="@/assets/img/newarc_logo_dark.png" />
          <h5 class="mt-0 mb-2 f14-ls03 lg:text-374151 text-53 lh-23 text-center lg:text-left">
            Con l'aiuto della tecnologia<br class="lg:block hidden"> semplifichiamo e miglioriamo<br class="lg:block hidden"> il
            processo di compravendita<br class="lg:block hidden"> immobiliare.
          </h5>
          <div class="text-center lg:text-left py-3 lg:py-0 mt-6 border-bottom-1 border-top-1 lg:border-0 footer-social-media">
            <a
              class="lg:mr-3 px-4 lg:px-0 footer-social-media-link text-green"
              href="location.href='https://www.linkedin.com/company/newarc-homes/';"
            >
              <i class="fab fa-facebook-f" ></i>
            </a>
            <a
              class="lg:mr-3 px-4 lg:px-0 text-green footer-social-media-link"
              href="location.href='https://www.linkedin.com/company/newarc-homes/';"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              class="px-4 lg:px-0 text-green footer-social-media-link" 
              href="location.href='https://www.linkedin.com/company/newarc-homes/';"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
        <div class="w-full lg:w-2/3 lg:px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 lg:mt-0 mt-5 lg:px-4 ml-auto">
              <div class="f16 lh-26 text-black font-bold sm:right npb-3">
                Scopri
              </div>
              <ul class="list-unstyled">
                <li class="lh-26 npy-3">
                  <a class="f15 text-62" href="https://newarc.it" > Valuta la tua casa </a>
                </li>
                <li class="flex items-center">
                  <router-link class="f15 text-62" to="/compra" >
                    Compra Casa
                  </router-link>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://www.newarc.it/le-case-newarc/" > Le case Newarc </a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://www.newarc.it/newarc-ai/" > Newarc AI</a>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 lg:mt-0 mt-5 lg:px-4 ml-auto">
              <div class="f16 lh-26 text-black font-bold sm:right npb-3">
                Sei un'agenzia?
              </div>
              <ul class="list-unstyled">
                <li class="lh-26 npy-3">
                  <a class="f15 text-62" href="https://www.newarc.it/programma-agenzie/" >Scopri il programma</a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://agenzie.newarc.it/#/register" >Iscriviti ora</a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://agenzie.newarc.it/#/" >Accedi all'area riservata</a>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 lg:mt-0 mt-5 lg:px-4 ml-auto">
              <div class="f16 lh-26 text-black font-bold sm:right npb-3">
                Informazioni
              </div>
              <ul class="list-unstyled">
                <li class="lh-26 npy-3">
                  <a class="f15 text-62" href="https://www.newarc.it/chi-siamo/" >Chi siamo</a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://www.newarc.it/domande-frequenti/"  >Domande frequenti</a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://www.newarc.it/contattaci/" >Contattaci</a>
                </li>
                <li class="lh-26 npy-3">
                  <a class="f15 lh-26 text-62" href="https://www.newarc.it/termini-e-condizioni/" >Termini e condizioni</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </footer>
  <div class="flex lg:flex-row flex-col-reverse px-5 lg:py-5 justify-between footer-info">
    <div class="footer-link text-89 lh-24 font-bold text-center lg:text-left lg:py-0 py-5 lg:flex lg:flex-row">
      <span>Newarc Srl </span>
      <br class="lg:hidden block"><span class="lg:block hidden">–</span> 
      <span>Via Vittorio Emanuele II 29, Chieri </span>
      <br class="lg:hidden block"><span class="lg:block hidden">–</span> 
      <span>P.Iva 12533550013</span>
    </div>
    <div class="flex flex-row footer-social-media items-center lg:py-0 py-5 justify-center lg:justify-start">
      <a
        class="footer-link text-89 lh-24 font-bold"
        href="https://www.iubenda.com/privacy-policy/19611190"
      >
        Privacy Policy
      </a>
      <p class="footer-link text-89 lh-24 font-bold px-2">-</p>
      <!-- <p class="f13-ls028 text-89 lh-24 font-bold px-2 lg:hidden block">.</p> -->
      <a
        class="f13-ls028 text-89 lh-24 font-bold"
        href="https://www.iubenda.com/privacy-policy/19611190/cookie-policy"
      >
        Cookie Policy 
      </a>
    </div>
  </div>
</div>  
</template>
<script>
export default {
  mounted(){
    this.getRouteName();
  },
  methods: {
    getRouteName(){
      // console.log('hello',this.$route.name);
    }
  },
  data() {
    return {
      date: new Date().getFullYear(),
      routeName: this.$route.name
    };
  },
};
</script>
